import React, { useState, useEffect, useRef } from 'react';

import Timer from 'react-compound-timerv2/build';
import { formatTime } from '../../utils';

const ContadorTempo = (props: { horaInicio: string; roomToken: string }) => {
  const [diffAgoraParaInicio, setDiffAgoraParaInicio] = useState<number>();
  const [horaInicio, setHoraInicio] = useState(props.horaInicio);
  const timer = useRef<Timer>(null);

  useEffect(
    function iniciarContador() {
      const startDate = new Date();
      if (horaInicio) {
        startDate.setHours(parseInt(horaInicio.split(':')[0]));
        startDate.setMinutes(parseInt(horaInicio.split(':')[1]));
        console.log('ContadorTempo - startDate', startDate);
      }

      const now = new Date();
      const localDiffAgoraParaInicio = now.getTime() - startDate.getTime();
      setDiffAgoraParaInicio(localDiffAgoraParaInicio);
      if (localDiffAgoraParaInicio && localDiffAgoraParaInicio < 0) setDiffAgoraParaInicio(0);
    },
    [horaInicio],
  );

  useEffect(
    function recuperarInicioDoLocalStorage() {
      if (horaInicio) {
        return;
      }

      try {
        const startTime = window.localStorage.getItem(`${props.roomToken}.startTime`);
        if (startTime) {
          setHoraInicio(startTime);
        } else {
          const startTime = new Date();
          const startTimeString = `${startTime.getHours()}:${startTime.getMinutes()}`;
          window.localStorage.setItem(`${props.roomToken}.startTime`, startTimeString);
          setHoraInicio(startTimeString);
        }
      } catch (e) {
        console.log('ContadorTempo:recuperarInicioDoLocalStorage - error using local storage', e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.roomToken],
  );

  return (
    <div className="clock text-center">
      <span className="tempoDeReuniaoLabel">tempo de reunião: </span>
      <span className="tempoDeReuniao">
        {
          //O componente de timer não se atualiza baseado no state, então é necessário fazer
          // essa gambs de criar um div com a key sendo o state para forçar a recriação do componente.
          // https://github.com/volkov97/react-compound-timer/issues/31
        }
        <span key={diffAgoraParaInicio}>
          <Timer formatValue={formatTime} ref={timer} initialTime={diffAgoraParaInicio}>
            <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
          </Timer>
        </span>
      </span>
    </div>
  );
};

export default ContadorTempo;
