import React, { useState, useEffect, useRef, forwardRef, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMicrophoneSlash, faWifi, faSlash } from '@fortawesome/free-solid-svg-icons';
import imgPersonPlaceHolder from '../../assets/Portrait_Placeholder.png';

// nao apagar - essa lib iguala as apis dos diferentes navegadores à ultima spec do webrtc
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';
import MicFeedback from './componentMicFeedback';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type VideoContainerProps = {
  audioMediaStream: MediaStream;
  microfoneLigado: boolean;
  reproduzirAudio?: boolean;
  videoClassName?: string;
  containerClassName?: string;
  nomeParticipante: string;
  cameraLigada: boolean;
  gridArea?: string;
  streamId?: string;
  slowConnection?: boolean;
  onSpeaking?: (streamId: string) => void;
  onMicInativo?: () => void;
  onForceMicRefresh?: () => void;
  monitorarProblemasDeMicrofone: boolean;
  shouldZoomOnBigContainer?: boolean;
  pronto: boolean;
};

const VideoContainer = (props: VideoContainerProps, ref: React.ForwardedRef<HTMLVideoElement>) => {
  const [streamChangeCount, setStreamChangeCount] = useState(0);
  const [videoStyle, setVideoStyle] = useState<CSSProperties>({});

  useEffect(
    function atualizarMicFeedback() {
      setStreamChangeCount(prevCount => prevCount + 1);
    },
    [props.cameraLigada],
  );

  useEffect(
    function setupEsticarVideo() {
      //@ts-ignore
      const video = ref.current as HTMLVideoElement;

      const adjustObjectFit = () => {
        if (!props.shouldZoomOnBigContainer) return;

        const aspectRatio = video.videoWidth / video.videoHeight;
        if (aspectRatio > 1) {
          setVideoStyle({
            objectFit: 'cover',
            maxWidth: '900px',
          });
        } else {
          setVideoStyle({
            objectFit: 'contain',
          });
        }
      };

      video.onplay = adjustObjectFit;
      video.onresize = adjustObjectFit;

      return () => {
        video.onplay = () => {};
        video.onresize = () => {};
      };
    },
    [props.shouldZoomOnBigContainer, ref],
  );

  return (
    <div
      className={`videoContainer ${props.containerClassName ? props.containerClassName : ''}`}
      style={{ gridArea: props.gridArea }}
    >
      <video
        muted={!props.reproduzirAudio}
        className={props.videoClassName + (props.cameraLigada ? ' ' : ' hidden')}
        playsInline
        style={videoStyle}
        ref={ref}
        autoPlay
      />

      {!props.cameraLigada && (
        <div
          className={props.videoClassName + ' videoContainer_noCamera'}
          style={{ backgroundImage: 'url(' + imgPersonPlaceHolder + ')' }}
        >
          &nbsp;
        </div>
      )}

      {props.slowConnection && (
        <div className="slowConnectionOverlay">
          <span className="fa-layers fa-fw slowConnection">
            <FontAwesomeIcon icon={faWifi as IconProp} title="Conexão falhando" className="icon slowConnection" />
            <FontAwesomeIcon icon={faSlash as IconProp} title="Conexão falhando" className="icon slowConnection" />
            <p>Conexão fraca</p>
          </span>
        </div>
      )}
      <div className="userStatusContainer">
        {!props.microfoneLigado && (
          <FontAwesomeIcon
            icon={faMicrophoneSlash as IconProp}
            title="Microfone desabilitado"
            className="icon remoteMic"
          />
        )}

        {props.microfoneLigado && streamChangeCount > 0 && (
          <>
            <MicFeedback
              microfoneLigado={props.microfoneLigado}
              stream={props.audioMediaStream}
              streamId={props.streamId}
              onSpeaking={props.onSpeaking}
              onMicInativo={props.onMicInativo}
              onForceMicRefresh={props.onForceMicRefresh}
              monitorarProblemasDeMicrofone={props.monitorarProblemasDeMicrofone}
            />
          </>
        )}

        <div className={`name ${props.cameraLigada ? '' : 'videoContainer-name_noCamera'}`}>
          {props.nomeParticipante}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(VideoContainer);
