import React, { useState, useRef } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { ReactMic } from 'react-mic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircle, faStop } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../infra/redux/hooks';
import { selectJoinRoom } from '../JoinRoomSlice';
import { useNavigate } from 'react-router-dom';
import { SebraeMGBotaoAvaliacao } from '../customerComponents/SebraeMG';
import { SebraeGOBotaoAvaliacao } from '../customerComponents/SebraeGO';
import { PsicologiaVivaBotaoAvaliacao } from '../customerComponents/PsicologiaViva';
import i18n from '../../../i18n/i18n';

type ModalProps = {
  show: boolean;
  onHide?: () => void;
};

const FatalErrorModal = (props: ModalProps) => {
  const navigate = useNavigate();
  const { show, onHide } = props;

  return (
    <Modal show={show} keyboard={true} onHide={onHide}>
      <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
        <span className="ms-2">{i18n.t('Sair da sala')}</span>
      </Modal.Header>
      <Modal.Body>
        <p>{i18n.t('Você quer sair da Sala?')}</p>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <SebraeMGBotaoAvaliacao />
        <SebraeGOBotaoAvaliacao />
        <PsicologiaVivaBotaoAvaliacao />
        <Button
          type="button"
          variant="outline-secondary"
          onClick={() => {
            window.postMessage('ROOM_EXIT');
            navigate('/exit');
          }}
        >
          {i18n.t('Sair')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FatalErrorModal;
