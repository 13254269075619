import React, { useState, useEffect, useCallback } from 'react';
import hark from 'hark';

// nao apagar - essa lib iguala as apis dos diferentes navegadores à ultima spec do webrtc
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';

type MicFeedbackProps = {
  stream: HTMLAudioElement | HTMLVideoElement | MediaStream | null | undefined;
  microfoneLigado: boolean;
  className?: string;
  streamId?: string;
  onSpeaking?: (streamId: string) => void;
  onMicInativo?: () => void;
  onForceMicRefresh?: () => void;
  monitorarProblemasDeMicrofone: boolean;
};

const MicFeedback = (props: MicFeedbackProps) => {
  const [micMonitor, setMicMonitor] = useState<hark.Harker>();
  const [lastPitches, setLastPitches] = useState([0, 0, 0]);
  const { streamId, onSpeaking, onMicInativo, onForceMicRefresh, stream } = props;

  useEffect(
    function configurarMicMonitor() {
      if (!stream) {
        return;
      }
      const newHark = hark(stream, { interval: 200 });

      newHark.on('volume_change', (volume: number) => {
        setLastPitches(prevState => {
          if (!isFinite(volume)) {
            return prevState;
          }
          if (prevState.indexOf(volume) !== -1) {
            return prevState;
          }
          if (prevState.length > 2) {
            prevState.shift();
          }

          prevState.push(Number.parseFloat(((100 + volume) / 100.0).toPrecision(2)));
          return [...prevState];
        });
      });
      setMicMonitor(prevState => {
        if (prevState) {
          prevState.stop();
        }
        return newHark;
      });
      return () => newHark.stop();
    },
    [stream],
  );

  useEffect(
    function ativarOuDesativarMicMonitor() {
      if (props.microfoneLigado && micMonitor) {
        micMonitor.resume();
      }
      if (!props.microfoneLigado && micMonitor) {
        micMonitor.suspend();
        setLastPitches([0, 0, 0]);
      }
    },
    [micMonitor, props.microfoneLigado],
  );

  const onSpeakingDelegate = useCallback(() => {
    if (onSpeaking) {
      onSpeaking(streamId!);
    }
  }, [onSpeaking, streamId]);

  useEffect(
    function notificarOnSpeaking() {
      if (!micMonitor) {
        return;
      }
      micMonitor.on('speaking', () => {
        onSpeakingDelegate();
      });
    },
    [micMonitor, onSpeakingDelegate],
  );

  const [mediaPitches, setMediaPitches] = useState(0);
  useEffect(
    function calcularMediaPitches() {
      const media = lastPitches.reduce((p, c) => p + c, 0) / lastPitches.length;
      setMediaPitches(Math.round(media * 100));
    },
    [lastPitches],
  );

  const [segundosDeMonitoramento, setSegundosDeMonitoramento] = useState(0);
  const [tempoMonitoramentoJaPassou, setTempoMonitoramentoJaPassou] = useState(false);
  const [iniciarMonitoramento, setIniciarMonitoramento] = useState(false);
  useEffect(
    function atualizarInicioMonitoramento() {
      setIniciarMonitoramento(
        !tempoMonitoramentoJaPassou && props.monitorarProblemasDeMicrofone && props.microfoneLigado,
      );
    },
    [props.monitorarProblemasDeMicrofone, props.microfoneLigado, tempoMonitoramentoJaPassou],
  );

  useEffect(
    function atualizarTempoMonitoramento() {
      if (iniciarMonitoramento) {
        const timer = setInterval(() => {
          setSegundosDeMonitoramento(prevState => prevState + 1);
        }, 1000);
        return () => clearInterval(timer);
      }
      setSegundosDeMonitoramento(0);
    },
    [iniciarMonitoramento],
  );

  const [microfoneFoiUsadoDuranteMonitoramento, setMicrofoneFoiUsadoDuranteMonitoramento] = useState(false);
  useEffect(
    function atualizarMicrofoneFoiUsado() {
      if (iniciarMonitoramento && segundosDeMonitoramento > 0 && segundosDeMonitoramento < 20 && mediaPitches > 5) {
        setMicrofoneFoiUsadoDuranteMonitoramento(true);
      }
    },
    [iniciarMonitoramento, mediaPitches, segundosDeMonitoramento],
  );

  const [tempoParaRefreshJaPassou, setTempoParaRefreshJaPassou] = useState(false);

  useEffect(
    function atualizarMonitoramentoJaPassou() {
      if (segundosDeMonitoramento > 10) {
        setTempoParaRefreshJaPassou(true);
      }
      if (segundosDeMonitoramento > 20) {
        setTempoMonitoramentoJaPassou(true);
      }
    },
    [segundosDeMonitoramento],
  );

  const [notificarMicRefresh, setNotificarMicRefresh] = useState(true);
  useEffect(
    function executarNotificacaoMicRefresh() {
      if (
        tempoParaRefreshJaPassou &&
        !microfoneFoiUsadoDuranteMonitoramento &&
        notificarMicRefresh &&
        onForceMicRefresh
      ) {
        onForceMicRefresh();
        setNotificarMicRefresh(false);
      }
    },
    [microfoneFoiUsadoDuranteMonitoramento, tempoParaRefreshJaPassou, notificarMicRefresh, onForceMicRefresh],
  );

  const [notificarMicInativo, setNotificarMicInativo] = useState(true);

  useEffect(
    function executarNotificacaoMicInativo() {
      if (tempoMonitoramentoJaPassou && !microfoneFoiUsadoDuranteMonitoramento && notificarMicInativo && onMicInativo) {
        onMicInativo();
        setNotificarMicInativo(false);
      }
    },
    [
      onMicInativo,
      setNotificarMicInativo,
      tempoMonitoramentoJaPassou,
      microfoneFoiUsadoDuranteMonitoramento,
      notificarMicInativo,
    ],
  );

  return (
    <div id="micFeedback" className={'micFeedback ' + props.className} style={{ height: '1em' }}>
      {lastPitches.map((pitch, index) => {
        return (
          <div
            key={pitch + '_' + index}
            style={{
              backgroundColor: '#a3d6ae',
              width: '0.2em',
              height: pitch * 1 + 'em',
              display: 'inline-block',
            }}
            className="rounded ms-1 pitchBar"
          >
            &nbsp;
          </div>
        );
      })}
    </div>
  );
};

export default MicFeedback;
