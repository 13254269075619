import React, { useEffect } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';

const PageRoomExpired = () => {
  return (
    <Container>
      <Row>
        <Col md="12" className="mt-5">
          <Row>
            <Card className="col-md-4 offset-md-4 text-center">
              <Card.Body>
                <Card.Title>Sua sala expirou!</Card.Title>
                <Card.Text>
                  <p>O limite de tempo da sala foi atingido.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PageRoomExpired;
