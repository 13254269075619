import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infra/redux/reduxStore';

export interface DevicesState {
  detectRTCloaded: boolean;
  hasMicCamPermission: boolean;
  permissionsFetched: boolean;
  cameraSelecionada?: string;
  camEnabled: boolean;
  micSelecionado?: string;
  micEnabled: boolean;
  temCamera: boolean;
}

const initialState: DevicesState = {
  detectRTCloaded: false,
  hasMicCamPermission: false,
  permissionsFetched: false,
  micEnabled: true,
  temCamera: true,
  camEnabled: true,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    fetchedPermissions: currentState => ({
      ...currentState,
      permissionsFetched: true,
    }),
    setHasMicCamPermission: (currentState, payload: PayloadAction<boolean>) => {
      return {
        ...currentState,
        hasMicCamPermission: payload.payload,
      };
    },
    setCameraSelecionada: (currentState, payload: PayloadAction<string>) => {
      return {
        ...currentState,
        cameraSelecionada: payload.payload,
        //forçamos o temCamera = true quando troca a camera pq setamos para false forçadamente quando ocorre um erro ao pegar o video.
        temCamera: true,
        camEnabled: true,
      };
    },
    setMicSelecionado: (currentState, payload: PayloadAction<string>) => {
      return {
        ...currentState,
        micSelecionado: payload.payload,
      };
    },
    setMicEnabled: (currentState, payload: PayloadAction<boolean>) => {
      return {
        ...currentState,
        micEnabled: payload.payload,
      };
    },
    detectRTCLoaded: currentState => {
      return {
        ...currentState,
        detectRTCloaded: true,
      };
    },
    setTemCamera: (currentState, payload: PayloadAction<boolean>) => {
      const result = {
        ...currentState,
        temCamera: payload.payload,
      };

      if (!result.temCamera) {
        result.camEnabled = false;
      }

      return result;
    },
    setCamEnabled: (currentState, payload: PayloadAction<boolean>) => {
      return {
        ...currentState,
        camEnabled: payload.payload,
      };
    },

    /**
     * Este metodo existe para setar camera e mic ao mesmo tempo, evitando realizar duas chamadas diferentes o que resultaria em invocar o getMediaStream 2x.
     */
    setCameraEMicSelecionados: (
      currentState,
      payload: PayloadAction<{ cameraSelecionada: string; micSelecionado: string }>,
    ) => {
      return {
        ...currentState,
        cameraSelecionada: payload.payload.cameraSelecionada,
        micSelecionado: payload.payload.micSelecionado,
      };
    },
  },
});

export const {
  fetchedPermissions,
  setHasMicCamPermission,
  setCameraSelecionada,
  setMicSelecionado,
  setMicEnabled,
  setCameraEMicSelecionados,
  setTemCamera,
  setCamEnabled,
  detectRTCLoaded,
} = devicesSlice.actions;

export const selectDevices = (state: RootState) => state.devices;

export default devicesSlice.reducer;
