const pt_br = {
  'Código da Sala': 'Código da sala',

  'Seu nome': 'Seu nome',
  Aviso: 'Aviso',
  Entrar: 'Entrar',
  suporte: 'Suporte',
  Conectando: 'Conectando...',
  'Conexão fraca': 'Conexão fraca',
  'Camera e microfone bloqueados': 'Camera e microfone bloqueados',
  'Queda de conexão': 'queda de conexão',
  'Tentando reconectar': 'Tentando reconectar...',
  acessoNaoLiberado:
    'Você não liberou o acesso para sua camera / microfone. Sem este acesso não será possível continuar. Por favor, libere o acesso e recarregue a página.',
  'pv.error.consultapassada': 'Você está acessando uma consulta que já terminou.',
  'pv.error.consultapassada.title': 'Consulta Finalizada!',
  'pv.error.tokennaoexiste': 'Você está acessando uma sala que não foi criada pelo Psicologia Viva.',
  'pv.error.tokennaoexiste.title': 'Sala não existe!',
  'pv.error.consultafutura':
    'Você está acessando uma consulta futura, ainda não está na hora de sua consulta. Volte mais tarde.',
  'pv.error.consultafutura.title': 'Consulta futura!',
  'pv.error.consultacancelada': 'Você está acessando uma consulta que foi cancelada, não é possível acessar essa sala.',
  'pv.error.consultacancelada.title': 'Consulta cancelada!',
  'pv.error.linkpsicologojautilizado':
    'O link que você está tentando acessar é gerado apenas para o Psicólogo utilizar. Estamos direcionando você para o link de acesso de paciente.',
  'pv.error.linkpsicologojautilizado.title': 'Acesso do Profissional de Psicologia',
  'pv.error.linkpsicologojautilizadobloqueioentradapac':
    'O link que você está tentando acessar é gerado exclusivamente para o acesso do Psicólogo, e por isso não pode ser compartilhado. Para entrar na sala de consulta, acesse sua conta na Plataforma.',
  'pv.error.linkpsicologojautilizadobloqueioentradapac': 'Acesso do Profissional de Psicologia',
  'pv.error.aguardandoentradapsicologo.title': 'Aguardando Psicólogo!',
  'pv.error.aguardandoentradapsicologo': 'Você precisa aguardar a entrada do psicólogo para acessar a sala!',
  'pv.error.wrongOrMissingType': 'Certifique-se que tenha acessado o link através do seu login na plataforma.',
  'pv.error.wrongOrMissingType.title': 'Link inválido!',
  'pv.error.convenioinativo.title': 'Convênio bloqueado!',
  'pv.error.convenioinativo': 'Você está acessando uma consulta de um convênio que foi bloqueado!',
  'pv.error.pacientedesativado.title': 'Paciente Desativado!',
  'pv.error.error.pacientedesativado':
    'Você está acessando uma consulta em que o paciente está bloqueado na plataforma!',
  'Link Não Encontrado.title': 'Erro',
  'Falar com o suporte': 'Falar com o suporte',
  'Abrir um chamado': 'Abrir um chamado',
  'Sair e enviar feedback': 'Sair e enviar feedback',
  'Você saiu da sala': 'Você saiu da sala',
  'Obrigado por acessar!': 'Obrigado por acessar!',
  'Voltar para a sala': 'Voltar para a sala',
  'Você quer sair da Sala?': 'Você quer sair da Sala?',
  'Sair da sala': 'Sair da sala',
  'O id da sala é obrigatório': 'O id da sala é obrigatório',
  'O nome de usuário é obrigatório': ' O nome de usuário é obrigatório',
  Desfocar: 'Desfocar',
  'Sem efeitos': 'Sem efeitos',
  Efeitos: 'Efeitos',
  Sair: 'Sair',
  solicitacaoAcessoCam:
    'Para usar a sala, precisamos de acesso à sua camera e microfone. Clique no botão para Continuar',
  Continuar: 'Continuar',
};
export default pt_br;
