import { useEffect } from 'react';
import { useAppSelector } from '../../../infra/redux/hooks';
import { selectJoinRoom } from '../JoinRoomSlice';

const dominioCliente = 'video.vitazure';
const rolePaciente = 'paciente';
const rolePsicologo = 'psicologo';

export function useCustomJS() {
  const joinRoomState = useAppSelector(selectJoinRoom);
  useEffect(() => {
    if (window.location.href.includes(dominioCliente) && joinRoomState.urlParams.perfil === rolePaciente) {
      var head = document.head;
      var style = document.createElement('style');

      const css = `
      .inviteBtnContainer,
      .blurBtnContainer,
      .shareScreenBtnContainer { 
        display: none;
      }`;

      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  }, [joinRoomState.urlParams.perfil]);

  return null;
}
