import { BodyPix } from '@tensorflow-models/body-pix';

import React, { useEffect } from 'react';
import { BackgroundConfig } from './helpers/backgroundHelper';
import { PostProcessingConfig } from './helpers/postProcessingHelper';
import { SegmentationConfig } from './helpers/segmentationHelper';
import { SourcePlayback } from './helpers/sourceHelper';
import useRenderingPipeline from './useRenderingPipeline';
import { TFLite } from './useTFLite';

type OutputViewerProps = {
  sourcePlayback: SourcePlayback;
  backgroundConfig: BackgroundConfig;
  segmentationConfig: SegmentationConfig;
  postProcessingConfig: PostProcessingConfig;
  bodyPix?: BodyPix;
  tflite: TFLite;
  ejectCanvas: (canvas: React.MutableRefObject<HTMLCanvasElement>) => void;
};

function OutputViewer(props: OutputViewerProps) {
  const {
    pipeline,
    backgroundImageRef,
    canvasRef,
    fps,
    durations: [resizingDuration, inferenceDuration, postProcessingDuration],
  } = useRenderingPipeline(
    props.sourcePlayback,
    props.backgroundConfig,
    props.segmentationConfig,
    props.tflite,
    props.bodyPix,
  );

  useEffect(
    function ejectInternalCanvas() {
      props.ejectCanvas(canvasRef);
    },
    [
      /**nao tem nada de proposito, só ejete o canvas quando o componente carrega */
    ],
  );

  useEffect(() => {
    if (pipeline) {
      pipeline.updatePostProcessingConfig(props.postProcessingConfig);
    }
  }, [pipeline, props.postProcessingConfig]);

  const statDetails = [
    `resizing ${resizingDuration}ms`,
    `inference ${inferenceDuration}ms`,
    `post-processing ${postProcessingDuration}ms`,
  ];
  const stats = `${Math.round(fps)} fps (${statDetails.join(', ')})`;

  return (
    <div style={{ flex: 1, position: 'relative' }}>
      {props.backgroundConfig.type === 'image' && (
        <img
          ref={backgroundImageRef}
          style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
          src={props.backgroundConfig.url}
          alt=""
          hidden={props.segmentationConfig.pipeline === 'webgl2'}
        />
      )}
      <canvas
        // The key attribute is required to create a new canvas when switching
        // context mode
        key={props.segmentationConfig.pipeline}
        ref={canvasRef}
        style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
        width={props.sourcePlayback.width}
        height={props.sourcePlayback.height}
      />
      <p
        className="fpsCounter"
        style={{
          position: 'absolute',
          display: 'none',
          top: 0,
          right: 0,
          left: 0,
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.48)',
          color: '#FFF',
        }}
      >
        {stats}
      </p>
    </div>
  );
}

export default OutputViewer;
