import DetectRTC from 'detectrtc';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';
import { reduxStore } from '../../infra/redux/reduxStore';
import {
  fetchedPermissions,
  selectDevices,
  setCamEnabled,
  setCameraSelecionada,
  setHasMicCamPermission,
  setMicEnabled,
  setMicSelecionado,
  setTemCamera,
} from './DevicesSlice';
import { getWebRTCUserMedia } from './MediaDevices';
import { selectStreams, setMediaStream } from './StreamsSlice';

type devicesSelection = {
  cameraSelecionada?: string;
  micSelecionado?: string;
  camEnabled?: boolean;
  micEnabled?: boolean;
  forceNewStream?: boolean;
};

export default function useGetStream(onErrorGettingStream?: () => void, onSuccessGettingStream?: () => void) {
  const dispatch = useAppDispatch();
  const devicesState = useAppSelector(selectDevices);
  const streamsState = useAppSelector(selectStreams);

  const getStreamHandlingErrors = useCallback(
    (userMediaConfig: { videoDeviceId?: string; audioDeviceId?: string; withoutVideo?: boolean }) => {
      console.log('getStreamHandlingErrors', userMediaConfig);
      getWebRTCUserMedia(userMediaConfig)
        .then(newStream => {
          console.log('mediaStream trocado', newStream?.id);
          //Ao trocar a camera precisamos resetar o status da track
          //de audio para manter o mic desabilitado caso já estivesse
          //desabilitado com a camera anterior
          newStream.getAudioTracks()[0].enabled = reduxStore.getState().devices.micEnabled;
          dispatch(setMediaStream(newStream));
          dispatch(setHasMicCamPermission(true));
          if (onSuccessGettingStream) onSuccessGettingStream();
        })
        .catch(e => {
          //tentamos pegar stream soh de audio somente se for problema de camera travada por outro app (ou seja, se o usuário já tem permissão de camera)
          if (DetectRTC.isWebsiteHasWebcamPermissions) {
            console.log('erro ao pegar stream da webcam. Vamos retentar sem video', e);

            dispatch(setTemCamera(false));
            getWebRTCUserMedia({
              withoutVideo: true,
            })
              .then(streamSemVideo => {
                console.log('mediaStream trocado', streamSemVideo?.id);
                streamSemVideo.getAudioTracks()[0].enabled = reduxStore.getState().devices.micEnabled;
                dispatch(setMediaStream(streamSemVideo));
                toast.error('Erro ao carregar imagem da câmera. Sua conexão será apenas audio.', {
                  duration: 10000,
                  id: 'erro-carregar-video',
                });
              })
              .catch(e => {
                console.log('erro ao pegar stream sem video', e);
                dispatch(setHasMicCamPermission(false));
                if (onErrorGettingStream) onErrorGettingStream();
              });
          }
        })
        .finally(() => {
          dispatch(fetchedPermissions());
        });
    },
    [dispatch, onErrorGettingStream, onSuccessGettingStream],
  );

  const getNewStream = useCallback(
    (devicesSelection: devicesSelection) => {
      console.log('getNewStream', devicesSelection);

      //setCameraSelecionada deve vir antes do setCamEnabled pois o setCameraSelecionada força o enabled pra true
      if (devicesSelection.cameraSelecionada) dispatch(setCameraSelecionada(devicesSelection.cameraSelecionada));
      if (devicesSelection.camEnabled != undefined) dispatch(setCamEnabled(devicesSelection.camEnabled));
      if (devicesSelection.micEnabled != undefined) dispatch(setMicEnabled(devicesSelection.micEnabled));
      if (devicesSelection.micSelecionado) dispatch(setMicSelecionado(devicesSelection.micSelecionado));

      const devePegarNovaStream =
        devicesState.cameraSelecionada != devicesSelection.cameraSelecionada ||
        devicesState.micSelecionado != devicesSelection.micSelecionado ||
        devicesState.camEnabled != devicesSelection.camEnabled;

      console.log({ devePegarNovaStream });
      if (devePegarNovaStream || devicesSelection.forceNewStream) {
        getStreamHandlingErrors({
          videoDeviceId: devicesSelection.cameraSelecionada || devicesState.cameraSelecionada,
          audioDeviceId: devicesSelection.micSelecionado || devicesState.micSelecionado,
          withoutVideo: !devicesSelection.camEnabled,
        });
      }
    },
    [
      devicesState.camEnabled,
      devicesState.cameraSelecionada,
      devicesState.micSelecionado,
      dispatch,
      getStreamHandlingErrors,
    ],
  );

  return { getNewStream };
}
