import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationTriangle, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const FaltaPermissaoModal = (props: { show: boolean }) => {
  return (
    <Modal id={'FALTAPERMISSAOMODAL'} show={props.show} onHide={() => {}}>
      <Modal.Header>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle as IconProp} className="icon me-1 text-danger" />
          {i18n.t('Camera e microfone bloqueados')}
        </span>
      </Modal.Header>
      <Modal.Body>
        <p>
          Precisamos de acesso à sua câmera e microfone. Clique no icone
          <FontAwesomeIcon icon={faVideoSlash as IconProp} className="icon ms-1" /> na barra de endereço do seu
          navegador e libere o acesso.
        </p>
        <p>Pode ser necessário recarregar a página após a liberação.</p>
      </Modal.Body>
    </Modal>
  );
};
export default withTranslation('translations')(FaltaPermissaoModal);
