import DetectRTC from 'detectrtc';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';
import {
  detectRTCLoaded,
  fetchedPermissions,
  selectDevices,
  setHasMicCamPermission,
  setTemCamera,
} from './DevicesSlice';
import { selectStreams } from './StreamsSlice';

export default function useDetectRTC() {
  const dispatch = useAppDispatch();
  const devicesState = useAppSelector(selectDevices);

  useEffect(
    function checkRTC() {
      DetectRTC.load(() => {
        console.log('WEBVIEW', (window as any).webview);

        const hasMicOrCamPermission =
          DetectRTC.isWebsiteHasMicrophonePermissions ||
          DetectRTC.isWebsiteHasWebcamPermissions; /* comentado pois não estava carregando stream no webview. Aparentemente não eh mais necessário. ||
          (window as any).webview; */

        console.log('hasMicOrCamPermission', hasMicOrCamPermission);

        if (!devicesState.permissionsFetched) {
          dispatch(setHasMicCamPermission(hasMicOrCamPermission));
          if (hasMicOrCamPermission) {
            dispatch(fetchedPermissions());
          }
          dispatch(setTemCamera(DetectRTC.hasWebcam));
        }

        console.log('detectRTC.hasMicrophone', DetectRTC.hasMicrophone);
        console.log('detectRTC.hasWebcam', DetectRTC.hasWebcam);
        console.log('detectRTC.isWebsiteHasMicrophonePermissions', DetectRTC.isWebsiteHasMicrophonePermissions);
        console.log('detectRTC.isWebsiteHasWebcamPermissions', DetectRTC.isWebsiteHasWebcamPermissions);
        console.log('detectRTC.audioInputDevices', DetectRTC.audioInputDevices);
        console.log('detectRTC.videoInputDevices', DetectRTC.videoInputDevices);

        dispatch(detectRTCLoaded());
      });
    },
    [devicesState.permissionsFetched, dispatch],
  );
}
