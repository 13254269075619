import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion, Button, ButtonGroup, ListGroup, Nav, OverlayTrigger, Popover } from 'react-bootstrap';
import { CustomerComponentProps } from './CustomerComponentProps';
import {
  faEllipsisH,
  faThLarge,
  faHeadset,
  faCommentDots,
  faClipboard,
  faCross,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../i18n/i18n';
import { withTranslation } from 'react-i18next';

import whatsapp from '../../../assets/whatsapp.png';
import centeredLayout from '../../../assets/centeredLayout.svg';
import pipLayout from '../../../assets/pipLayout.png';

import './PsicologiaViva.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAppDispatch, useAppSelector } from '../../../infra/redux/hooks';
import { selectJoinRoom } from '../JoinRoomSlice';
import { selectToolbar, toogleShowDrawer } from '../ToolbarSlice';
import { isMobile } from 'react-device-detect';

function callChatPac() {
  window.open(
    'https://chat.blip.ai/?appKey=cHNpY29sb2dpYXZpdmEyOjQ0OGM1NDgwLWUwMjAtNGMzNS1hNTcyLTg2MDM0NWZjNGE5MA==',
    'myWindow',
    'width=500,height=500',
  ); // Opens a new window
}

function callChatPsi() {
  window.open(
    'https://api.whatsapp.com/send?phone=553191187917&text=Ol%C3%A1%2C%20estou%20em%20atendimento%20e%20estamos%20com%20um%20problema%20na%20sess%C3%A3o%2C%20voc%C3%AA%20poderia%20me%20auxiliar%3F',
    'myWindow',
    'width=500,height=500',
  ); // Opens a new window
}

const dominioCliente = 'psicologiaviva';
//parametro type
const rolePaciente = 'RHNDPO7AYB';
const rolePsicologo = 'Y0PW9P0FPD';

function isPsicologo(urlParams: any) {
  return urlParams.type === rolePsicologo;
}

function isPaciente(urlParams: any) {
  return urlParams.type === rolePaciente;
}

interface RightHeaderProps {
  setLayout: React.Dispatch<React.SetStateAction<'pipLayout' | 'centeredLayout ' | 'gridLayout'>>;
}

type PVRightHeaderProps = RightHeaderProps & CustomerComponentProps;
const RightHeaderContentInternal = (props: PVRightHeaderProps) => {
  const joinRoomState = useAppSelector(selectJoinRoom);
  const { setLayout } = props;

  if (!window.location.href.includes(dominioCliente)) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={'bottom'}
      overlay={
        <Popover id="popoverSuporte">
          <Accordion defaultActiveKey="0" alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{i18n.t('suporte')}</Accordion.Header>
              <Accordion.Body className=" suporte">
                <ListGroup className="px-2">
                  {isPaciente(joinRoomState.urlParams) && (
                    <ListGroup.Item action onClick={callChatPac}>
                      <FontAwesomeIcon className="me-1" style={{ fontSize: '17px' }} icon={faHeadset as IconProp} />
                      {i18n.t('Falar com o suporte')}
                    </ListGroup.Item>
                  )}
                  {isPsicologo(joinRoomState.urlParams) && (
                    <ListGroup.Item action onClick={callChatPsi}>
                      <img className="icon me-1 whatsapp" src={whatsapp} />
                      Whatsapp
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header> {i18n.t('Alterar layout')}</Accordion.Header>
              <Accordion.Body className="d-flex layouts">
                <button
                  onClick={() => {
                    setLayout('pipLayout');
                  }}
                  className="flex-grow-1"
                >
                  <img className="icon" style={{ filter: 'none', transform: 'none' }} src={pipLayout}></img>
                </button>

                <button
                  onClick={() => {
                    setLayout('gridLayout');
                  }}
                  className="flex-grow-1"
                >
                  <FontAwesomeIcon icon={faThLarge as IconProp} style={{ fontSize: '35px' }} className={'icon'} />
                </button>

                <button
                  onClick={() => {
                    setLayout('centeredLayout ');
                  }}
                  className="flex-grow-1"
                >
                  <img className="icon centeredLayout" src={centeredLayout}></img>
                </button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Popover>
      }
    >
      <Button variant="secondary">
        <FontAwesomeIcon icon={faEllipsisH as IconProp} />
      </Button>
    </OverlayTrigger>
  );
};

function PsicologiaVivaBotaoAvaliacaoInternal() {
  const joinRoomState = useAppSelector(selectJoinRoom);

  if (window.location.href.includes(dominioCliente)) {
    return (
      <Button
        type="button"
        variant="primary"
        onClick={() => {
          if (isPaciente(joinRoomState.urlParams)) {
            window.location.href = `https://consultorioonline.psicologiaviva.com.br/ext/avaliacao/${joinRoomState.customerRoomData.idConsulta}`;
          } else {
            window.location.href = `https://consultorioonline.psicologiaviva.com.br/ext/avaliacaopsicologo/${joinRoomState.customerRoomData.idConsulta}`;
          }
        }}
      >
        {i18n.t('Sair e enviar feedback')}
      </Button>
    );
  }
  return null;
}

function PsicologiaVivaToolBarButtons() {
  const dispatch = useAppDispatch();
  const joinRoomState = useAppSelector(selectJoinRoom);
  const toolbarState = useAppSelector(selectToolbar);

  if (window.location.href.includes(dominioCliente) && isPsicologo(joinRoomState.urlParams) && !isMobile) {
    return (
      <span className="btn-circle-container drawerBtnContainer">
        <button
          className={`btn-circle btn-md ${toolbarState.showDrawer ? 'active' : 'enabled'}`}
          title="Testes"
          onClick={() => dispatch(toogleShowDrawer())}
        >
          <FontAwesomeIcon icon={faClipboard as IconProp} className={'icon'} />
        </button>
        <label>{i18n.t('testes')}</label>
      </span>
    );
  }
  return null;
}

function PsicologiaVivaDrawerContent() {
  const toolbarState = useAppSelector(selectToolbar);
  const [testes] = useState([
    { label: 'DASS 21', url: 'https://psicologiaviva.typeform.com/dass-21#hidden1=xxxxx' },
    { label: 'Babel Ansiedade', url: 'https://psicologiaviva.typeform.com/babel-ansiedade#hidden1=xxxxx' },
    { label: 'Babel Depressão', url: 'https://psicologiaviva.typeform.com/babel-depressao#hidden1=xxxxx' },
    { label: 'Inven Ansiedade', url: 'https://psicologiaviva.typeform.com/inven-ansiedade#hidden1=xxxxx' },
    { label: 'Inven Depressão', url: 'https://psicologiaviva.typeform.com/inven-depressao#hidden1=xxxxx' },
  ]);

  const [urlTesteSelecionado, setURLTesteSelecionado] = useState<string>();

  return (
    <div id="pvDrawer">
      {!urlTesteSelecionado && (
        <div id="testsList">
          <h3>Selecione o teste</h3>
          <ButtonGroup vertical>
            {!urlTesteSelecionado &&
              testes.map((teste, index) => {
                return (
                  <Button key={teste.label} onClick={() => setURLTesteSelecionado(teste.url)}>
                    {teste.label}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      )}
      {urlTesteSelecionado && (
        <>
          <button id="PVcloseTestButton" onClick={() => setURLTesteSelecionado(undefined)}>
            <FontAwesomeIcon icon={faTimesCircle as IconProp} style={{ fontSize: 25 }} className={'icon'} />
          </button>
          <iframe
            src={urlTesteSelecionado}
            sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
            width={'100%'}
            height={'100%'}
          />
          ;
        </>
      )}
    </div>
  );
}

//@ts-ignore
const RightHeaderContent = withTranslation('translations')(RightHeaderContentInternal);
const PsicologiaVivaBotaoAvaliacao = withTranslation('translations')(PsicologiaVivaBotaoAvaliacaoInternal);
export { RightHeaderContent, PsicologiaVivaBotaoAvaliacao, PsicologiaVivaToolBarButtons, PsicologiaVivaDrawerContent };
