import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';

import io from 'socket.io-client';
import './MyVideoRoom.scss';
import './layouts/gridLayout.scss';
import './layouts/centeredLayout.scss';
import './layouts/pipLayout.scss';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import VideoContainer from './fragmentVideoContainer';
import VideoContainerSemRef from './fragmentVideoContainerSemRef';
import MicCamPicker from './MicCamPicker';

import TesteMicrofoneModal from './modals/fragmentTesteMicrofoneModal';
import ExitRoomModal from './modals/ExitRoomModal';
import FatalErrorModal from './modals/fragmentFatalErrorModal';

import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';

// nao apagar - essa lib iguala as apis dos diferentes navegadores à ultima spec do webrtc
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';

import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons';
import NewChatComponent from '../chat/ChatComponent';
import '../chat/ChatComponent.css';
import { isMobile, isSafari } from 'react-device-detect';
import toast, { Toaster } from 'react-hot-toast';

import PubSubWebRTCNegotiator from './PubSubWebRTCNegotiator';
import Timer from 'react-compound-timerv2/build';
import ReconectandoModal from './modals/ReconectandoModal';
import ConectandoModal from './modals/ConectandoModal';
import ConexaoLentaModal from './modals/ConexaoLentaModal';
import FalhaValidacaoEntradaModal from './modals/FalhaValidacaoEntradaModal';
import axios from 'axios';

import StreamVideoDebbuger from './StreamVideoDebbuger';

import ContadorTempo from './ContadorTempo';
import { formatTime } from '../../utils';

import { getWebRTCUserMedia } from './MediaDevices';
import { PsicologiaVivaDrawerContent, RightHeaderContent } from './customerComponents/PsicologiaViva';
import { joinRoom, selectJoinRoom, setCustomerParams } from './JoinRoomSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VirtualBackground from '../virtualBackground/VirtualBackground';
import { useCustomJSWrapper } from './customerComponents/useCustomJSWrapper';
import Toolbar from './Toolbar';
import { disableVBG } from '../virtualBackground/VirtualBackgroundSlice';

import { selectDevices, setMicEnabled, setTemCamera } from './DevicesSlice';
import { reduxStore } from '../../infra/redux/reduxStore';
import { selectStreams, setMediaStream, useActiveStream, useStreamPublishManager } from './StreamsSlice';

import { selectToolbar } from './ToolbarSlice';
import useDetectRTC from './useDetectRTC';

const socketHost = '/';

const MyVideoRoomComponent = props => {
  const [showStreamDebugger, setShowStreamDebugger] = useState(false);
  const location = useLocation();
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [horaInicio, setHoraInicio] = useState(urlParams.hora);
  let params = useParams();
  const navigate = useNavigate();

  const { register, handleSubmit, errors } = useForm();

  const [localName, setLocalName] = useState(urlParams.user);

  const toolbarState = useAppSelector(selectToolbar);

  const [slowConnection, setSlowConnection] = useState(false);

  const [pronto, setPronto] = useState(false);

  //Usado para representar quando um cliente entra na sala e o botão "pronto"
  //não fica liberado pois o médico deve entrar antes.
  //Usado na Conexa, vem da API de validação de sala deles.
  //Quando alguém entra na sala, e estava nesse estado, limpamos o erro
  //para permitir o acesso à sala
  const [isAguardarMedico, setIsAguardarMedico] = useState(false);
  const [temMedicoNaSala, setTemMedicoNaSala] = useState(false);

  const [chatOpen, setChatOpen] = useState(false);
  const [shareScreen, setShareScreen] = useState(false);
  const [conectado, setConectado] = useState(false);

  const [someoneSharingScreen, setSomeoneSharingScreen] = useState(false);

  const [showModalReconectando, setShowModalReconectando] = useState(false);
  const [showModalConectando, setShowModalConectando] = useState(false);
  const [showModalSelecionarMicCam, setShowModalSelecionarMicCam] = useState(false);
  const [showExitRoomModal, setShowExitRoomModal] = useState(false);

  const [layout, setLayout] = useState('pipLayout');

  const [conectantes, setConectantes] = useState([]);

  // Variavel de estado apenas para forcar o render do VideoContainer
  // pois o myStream.current.srcObject eh um useRef que previne renderizacoes desnecessarias
  const [streamChangeCount, setStreamChangeCount] = useState(0);

  const myStream = useRef();
  const myScreenStream = useRef();
  const activeStream = useActiveStream();

  const localVideo = useRef();

  const socket = useRef();

  const negotiator = useRef();
  useStreamPublishManager(negotiator);
  useDetectRTC();

  const dispatch = useAppDispatch();
  const joinRoomState = useAppSelector(selectJoinRoom);
  const devicesState = useAppSelector(selectDevices);
  const streamsState = useAppSelector(selectStreams);
  const { token } = joinRoomState;

  //invoca scripts especificos de cada cliente
  useCustomJSWrapper();

  useEffect(function configurarEstadoInicial() {
    dispatch(joinRoom({ token: params.token, urlParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entrarNaSala = useCallback(
    function entrarNaSala(data) {
      if (!localName) {
        setLocalName(data.user);
      }

      setPronto(true);
      if (isMobile) {
        try {
          document.body.requestFullscreen().catch(err => {});
        } catch (err) {}
      }

      socket.current.emit('USUARIO_PRONTO_PARA_CONF', { token: token, userId: localName || data.user });
    },
    [localName, token],
  );

  function swapLayout() {
    if (layout === 'gridLayout') {
      setLayout('centeredLayout');
    } else if (layout === 'centeredLayout') {
      setLayout('pipLayout');
    } else {
      setLayout('gridLayout');
    }
  }

  const atualizarLayoutBaseadoNaQuantidadeDeConectantes = useCallback(
    function atualizarLayoutBaseadoNaQuantidadeDeConectantes() {
      if (someoneSharingScreen || shareScreen || window.innerHeight < 550) {
        setLayout('centeredLayout');
      } else if (conectantes.length <= 1) {
        setLayout('pipLayout');
      } else if (conectantes.length > 1) {
        if (window.innerWidth < 640) {
          setLayout('centeredLayout');
        } else {
          setLayout('gridLayout');
        }
      }
    },
    [conectantes.length, shareScreen, someoneSharingScreen],
  );
  useLayoutEffect(
    function definirLayoutCorretoSeForMobile() {
      window.onresize = atualizarLayoutBaseadoNaQuantidadeDeConectantes;
    },
    [atualizarLayoutBaseadoNaQuantidadeDeConectantes],
  );

  useEffect(atualizarLayoutBaseadoNaQuantidadeDeConectantes, [
    conectantes.length,
    someoneSharingScreen,
    shareScreen,
    atualizarLayoutBaseadoNaQuantidadeDeConectantes,
  ]);

  const [conectantesComMediaStream, setConectantesComMediaStream] = useState([]);
  useEffect(
    function atualizarConectantesComMediaStream() {
      setConectantesComMediaStream(prevConectantesComMediaStream => {
        return conectantes.map(conectante => {
          const novoConectanteComMediaStream = { ...conectante };
          const prevConectanteComMediaStream = prevConectantesComMediaStream.find(
            prevConectante => prevConectante.socketId === novoConectanteComMediaStream.socketId,
          );
          if (prevConectanteComMediaStream) {
            novoConectanteComMediaStream.videoStream = prevConectanteComMediaStream.videoStream;

            if (conectante.screenShareEnabled) {
              novoConectanteComMediaStream.screenStream = prevConectanteComMediaStream.screenStream;
            } else {
              novoConectanteComMediaStream.screenStream = null;
            }
          }
          return novoConectanteComMediaStream;
        });
      });
    },
    [conectantes],
  );

  useEffect(
    function popupEarlyConectando() {
      if (pronto && conectantes.length > 0 && !conectado) {
        setShowModalConectando(true);
      }
    },
    [conectado, conectantes, pronto],
  );

  const [showFatalErrorModal, setShowFatalErrorModal] = useState(false);

  function atualizarParticipantes(atualizarParticipantesCMD) {
    console.log('ATUALIZAR_PARTICIPANTES', atualizarParticipantesCMD);

    const participantesSemEu = [];
    let eu = undefined;
    atualizarParticipantesCMD.participantes.forEach(p => {
      if (p.socketId !== socket.current.id) {
        if (p.status === 'PRONTO') {
          participantesSemEu.push(p);
        }
      } else {
        eu = p;
      }
    });
    setSlowConnection(eu?.slowConnection);

    console.log('setTemMedicoNaSala', participantesSemEu.length > 0);
    setTemMedicoNaSala(participantesSemEu.length > 0);

    setSomeoneSharingScreen(participantesSemEu.some(p => p.screenShareEnabled));

    setConectantes(participantesSemEu);
  }

  useEffect(
    function entrarNaSala() {
      socket.current = io.connect(socketHost, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      //Esse handler precisa estar definido aqui pois um evento ATUALIZAR_PARTICIPANTES
      //pode ser disparado no onconnect, antes do useEffect que cria o handler para o ATUALIZAR_PARTICIPANTES
      //seja executado, e esse evento seria perdido.
      //Este cenário existe por causa da liberação de entrada do paciente depois que o médico entrou (apenas Conexa).
      socket.current.on('ATUALIZAR_PARTICIPANTES', atualizarParticipantes);

      window.socket = socket.current;

      const onConnecting = () => {
        setConectado(false);
        setShowModalConectando(true);
      };

      const onConnected = () => {
        setConectado(true);
        setShowModalConectando(false);
      };

      const onDisconnected = () => setConectado(false);
      const onFatalError = () => setShowFatalErrorModal(true);

      negotiator.current = new PubSubWebRTCNegotiator(socket.current, {
        onConnecting: onConnecting.bind(this),
        onConnected: onConnected.bind(this),
        onDisconnected: onDisconnected.bind(this),
        onFatalError: onFatalError.bind(this),
        onRemoteStream: (novaStream, publisherSocketId, tipo) => {
          setConectantesComMediaStream(prevConectantes => {
            return prevConectantes.map(conectante => {
              const conectanteComStreams = { ...conectante };
              if (conectante.socketId === publisherSocketId) {
                if (tipo == 'video') {
                  conectanteComStreams.videoStream = novaStream;
                } else if (tipo == 'screen') {
                  conectanteComStreams.screenStream = novaStream;
                } else {
                  throw new Error(`tipo de stream desconhecido ${tipo}`);
                }
              }
              return conectanteComStreams;
            });
          });

          setStreamChangeCount(prevState => prevState + 1);
        },
      });

      socket.current.io.off('error');
      socket.current.io.on('error', async function(error) {
        console.log('error', error);
      });

      socket.current.off('disconnect');
      socket.current.on('disconnect', async function() {
        console.log('socket disconnected');
        stopScreenShare();
        setConectado(false);
        negotiator.current.disconnect();
        setShowModalReconectando(true);
      });

      const entrarNaSalaInitialParams = {
        token: token,
        userId: localName,
        micEnabled: true,
        camEnabled: true,
        screenShareEnabled: false,
        status: 'NAO_PRONTO',
        urlParams: Object.assign(urlParams),
      };
      socket.current.emit('ENTRAR_NA_SALA', entrarNaSalaInitialParams);

      return () => {
        try {
          console.log('disconnect cleanup');
          socket.current.off('disconnect');
          socket.current.disconnect();
          negotiator.current.disconnect();
        } catch (err) {}
      };
    },
    [
      /* Não tem o localName de propósito, não devemos entrar na sala novamente quando o usuário preenche o nome */
      token,
    ],
  );

  const [canShareScreen, setCanShareScreen] = useState(false);
  useEffect(
    function updateCanShareScreen() {
      setCanShareScreen(shareScreen || conectantesComMediaStream.length > 0);
    },
    [conectantesComMediaStream.length, shareScreen],
  );

  //Ja inicializo com mensagem Vazia para não habilitar a entrada até o retorno do validarSala
  const [msgErroAoEntrarNaSala, setMsgErroAoEntrarNaSala] = useState(undefined);
  const [validacaoRealizada, setValidacaoRealizada] = useState(false);
  const [ficarProntoAutomaticamente, setFicarProntoAutomaticamente] = useState(false);
  useEffect(
    function validarAcessoSala() {
      async function validarEntradaAsync() {
        console.log('validando Sala', token);
        if (token == null || token.trim() == '') {
          return;
        }
        const resp = await axios.get(`/validarSala/${token}${location.search}`);
        let customerRoomData;
        if (resp.data.status == 'INVALID') {
          if (resp.data.invalidMsgBundle) {
            setMsgErroAoEntrarNaSala(resp.data.invalidMsgBundle);
          } else {
            setMsgErroAoEntrarNaSala('Erro ao validar acesso à sala. Entre em contato com o suporte.');
          }

          if (resp.data.customFields?.aguardandoMedico) {
            setIsAguardarMedico(resp.data.customFields.aguardandoMedico);
          }

          if (resp.data.invalidMsgBundle === 'pv.error.linkpsicologojautilizado' && urlParams.type == 'Y0PW9P0FPD') {
            setTimeout(() => {
              var url = new URL(window.location.href);
              window.location =
                'https://' +
                url.hostname.replaceAll('.psicologiaviva.com.br', '') +
                '.psicologiaviva.com.br/room/' +
                token +
                '?type=RHNDPO7AYB';
            }, 7000);
          }
        } else {
          setMsgErroAoEntrarNaSala(undefined);
          customerRoomData = resp.data.clientRoomData;
          dispatch(setCustomerParams({ customerRoomData }));
        }
        setFicarProntoAutomaticamente(resp.data.configuracaoSala.ficarProntoAutomaticamente);
        setValidacaoRealizada(true);
        console.log('validacaoData', resp.data);

        if (customerRoomData && customerRoomData.whiteLabel === 'S') {
          var head = document.head;
          var style = document.createElement('style');

          const css = `:root { 
            --customer-theme-primary-color: ${customerRoomData.corSistema}; 
            --customer-theme-primary-color-shadow: ${customerRoomData.corSistema}70; 
            --customer-theme-secondary-color: ${
              customerRoomData.corSecundariaSistema
                ? customerRoomData.corSecundariaSistema
                : customerRoomData.corSistema
            };
            --customer-logo: url(${customerRoomData.urlLogoSistema})
          }`;

          style.appendChild(document.createTextNode(css));

          head.appendChild(style);
        }

        const dataInicioReuniao = resp.data.dataInicio;
        const dataComHoraRegxp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/gm;
        if (dataInicioReuniao && dataInicioReuniao.match(dataComHoraRegxp)) {
          setHoraInicio(dataInicioReuniao.split(' ')['1']);
        }

        socket.current.emit('REGISTRAR_DADOS_CLIENTE', customerRoomData);
      }
      validarEntradaAsync();
    },
    [dispatch, location.search, token, urlParams.type, setIsAguardarMedico],
  );

  useEffect(
    function entrarNaSalaQuandoForFicarProntoAutomaticamente() {
      if (ficarProntoAutomaticamente && !pronto && validacaoRealizada && !msgErroAoEntrarNaSala && activeStream) {
        entrarNaSala();
      }
    },
    [activeStream, entrarNaSala, ficarProntoAutomaticamente, msgErroAoEntrarNaSala, pronto, validacaoRealizada],
  );

  useEffect(
    function liberarPacienteSeTemMedicoNaSala() {
      console.log('liberarPacienteSeTemMedicoNaSala', {
        isAguardarMedico,
        temMedicoNaSala,
        pronto,
        ficarProntoAutomaticamente,
      });
      if (isAguardarMedico && temMedicoNaSala && !pronto) {
        setMsgErroAoEntrarNaSala(undefined);
        if (ficarProntoAutomaticamente) {
          console.log('entrando na sala por ficarProntoAutomaticamente');
          entrarNaSala();
        }
      }
    },
    [entrarNaSala, ficarProntoAutomaticamente, isAguardarMedico, pronto, temMedicoNaSala],
  );

  useEffect(
    function atualizarEstadoParticipantes() {
      socket.current.off('ATUALIZAR_PARTICIPANTES');
      socket.current.on('ATUALIZAR_PARTICIPANTES', atualizarParticipantes);
    },
    [pronto, shareScreen, layout],
  );

  useEffect(
    function tratarExpiracaoPorLimiteDeTempo() {
      socket.current.off('SALA_EXPIRADA_POR_LIMITE_DE_TEMPO');
      socket.current.on('SALA_EXPIRADA_POR_LIMITE_DE_TEMPO', () => {
        window.location.href = '/roomExpired';
      });

      socket.current.off('PROXIMIDADE_EXPIRACAO_POR_LIMITE_DE_TEMPO');
      socket.current.on('PROXIMIDADE_EXPIRACAO_POR_LIMITE_DE_TEMPO', horaDesconexao => {
        console.log(`alerta de tempo de reuniao atingido. deconecao em ${horaDesconexao}`);
        toast.error(`Tempo de reunião atingido! Você será desconectado às ${horaDesconexao}`, {
          position: 'bottom-center',
          duration: 15000,
          id: 'PROXIMIDADE_EXPIRACAO',
        });
      });
    },
    [pronto],
  );

  useEffect(
    function configurarReconexao() {
      socket.current.io.off('reconnect_attempt');
      socket.current.io.on('reconnect_attempt', function(e) {
        console.log('reconnect_attempt', e);
      });

      socket.current.io.off('reconnect');
      let conectarMidiaTimeout;
      socket.current.io.on('reconnect', async function() {
        console.log('reconecting');
        const entrarNaSalaParams = {
          token: token,
          userId: localName,
          micEnabled: devicesState.micEnabled,
          camEnabled: devicesState.camEnabled,
          screenShareEnabled: shareScreen,
          status: pronto ? 'PRONTO' : 'NAO_PRONTO',
          urlParams: Object.assign(urlParams),
        };

        console.log('RECONECT ENTRANDO NA SALA', entrarNaSalaParams);
        socket.current.emit('ENTRAR_NA_SALA', entrarNaSalaParams);

        if (pronto) {
          console.log('reiniciando peerConnection');
          negotiator.current.setupPublisherConnection();
        }

        setShowModalReconectando(false);
      });
      return () => {
        if (conectarMidiaTimeout) {
          clearTimeout(conectarMidiaTimeout);
        }
      };
    },
    [token, localName, devicesState.camEnabled, devicesState.micEnabled, shareScreen, pronto, urlParams],
  );

  useEffect(
    function notificarServerOnCamToggle() {
      let videoSettings;
      if (myStream.current) {
        const videoTrack = myStream.current.getVideoTracks()[0];
        if (videoTrack) {
          videoTrack.enabled = devicesState.camEnabled;
          videoSettings = videoTrack.getSettings();
        }
      }
      if (pronto) {
        const StreamChangeParams = {
          token: token,
          userId: localName,
          enabled: devicesState.camEnabled,
          resource: 'CAMERA',
          videoSettings: {
            width: videoSettings?.width,
            height: videoSettings?.height,
          },
        };

        socket.current.emit('STREAM_STATUS_CHANGED', StreamChangeParams);
      }
    },
    [devicesState.camEnabled, localName, token, pronto],
  );

  useEffect(
    function notificarServerOnMicToggle() {
      if (myStream.current) myStream.current.getAudioTracks()[0].enabled = devicesState.micEnabled;

      if (pronto) {
        socket.current.emit('STREAM_STATUS_CHANGED', {
          token: token,
          userId: localName,
          enabled: devicesState.micEnabled,
          resource: 'MIC',
        });
      }
    },
    [devicesState.micEnabled, localName, token, pronto],
  );

  const stopScreenShare = useCallback(() => {
    setShareScreen(false);
    negotiator.current.stopScreenShare();
    if (myScreenStream.current) myScreenStream.current.getVideoTracks()[0].stop();
    socket.current.emit('STREAM_STATUS_CHANGED', {
      token: token,
      userId: localName,
      enabled: false,
      resource: 'SCREEN_SHARE',
    });
  }, [localName, token]);

  const startScreenShare = useCallback(() => {
    async function shareScreen() {
      try {
        //Não chamo o negotiator.current.shareScreen aqui pois ele precisa ser
        // a ação direta de um clique para que o safari não bloqueie. chamamos direto no onClick.
        myScreenStream.current = await negotiator.current.shareScreen({
          onVideoTrackEnded: stopScreenShare,
        });

        const videoTrack = myScreenStream.current.getVideoTracks()[0];

        const streamVideoSettings = videoTrack ? videoTrack.getSettings() : undefined;

        socket.current.emit('STREAM_STATUS_CHANGED', {
          token: token,
          userId: localName,
          enabled: true,
          resource: 'SCREEN_SHARE',
          videoSettings: {
            width: streamVideoSettings?.width,
            height: streamVideoSettings?.height,
          },
        });
        setShareScreen(true);
      } catch (err) {
        console.log('Erro compartilhando tela', err);
        setShareScreen(false);
        throw err;
      }
    }

    if (pronto) {
      shareScreen();
    }
  }, [localName, pronto, stopScreenShare, token]);
  //useEffect(reactTotoggleShareScreen, [reactTotoggleShareScreen]);

  const [remoteStreamIdQueEstaFalando, setRemoteStreamIdQueEstaFalando] = useState('');

  function onVideoSpeaking(streamId) {
    if (!streamId) {
      return;
    }
    setRemoteStreamIdQueEstaFalando(streamId);
  }

  const [mainConectante, setMainConectante] = useState();
  useEffect(
    function atualizarMainConectante() {
      if (conectantesComMediaStream.length === 0) {
        setMainConectante(null);
        return;
      }

      if (shareScreen) {
        setMainConectante(null);
        return;
      }

      const conectanteComScreenShare = conectantesComMediaStream.find(conectante => conectante.screenShareEnabled);
      if (conectanteComScreenShare) {
        setMainConectante({ ...conectanteComScreenShare });
        return;
      }
      if (!remoteStreamIdQueEstaFalando) {
        setMainConectante({ ...conectantesComMediaStream[0] });
        return;
      }
      const conectante = conectantesComMediaStream.find(c => c.socketId === remoteStreamIdQueEstaFalando);
      if (conectante) {
        setMainConectante({ ...conectante });
        return;
      }

      setMainConectante({ ...conectantesComMediaStream[0] });
      return;
    },
    [conectantesComMediaStream, remoteStreamIdQueEstaFalando, layout, shareScreen],
  );

  const [mainMicEnabled, setMainMicEnabled] = useState(false);
  useEffect(
    function atualizarMainMicEnabled() {
      if (!mainConectante) {
        setMainMicEnabled(false);
        return;
      }

      setMainMicEnabled(mainConectante.micEnabled);
    },
    [mainConectante],
  );

  const [mainCamEnabled, setMainCamEnabled] = useState(false);
  useEffect(
    function atualizarMainCamEnabled() {
      if (!mainConectante) {
        setMainCamEnabled(false);
        return;
      }

      setMainCamEnabled(mainConectante.camEnabled);
    },
    [mainConectante],
  );

  useEffect(
    function pararBlurSeTrocarDeCamera() {
      dispatch(disableVBG({}));
    },
    [devicesState.cameraSelecionada, dispatch],
  );

  const [mainName, setMainName] = useState('');
  useEffect(
    function atualizarMainName() {
      if (!mainConectante) {
        setMainName('');
        return;
      }

      setMainName(mainConectante.identificacao);
    },
    [mainConectante],
  );

  const [mainSlowConnection, setMainSlowConnection] = useState(false);
  useEffect(
    function atualizarMainSlowConnection() {
      if (!mainConectante) {
        setMainSlowConnection(false);
        return;
      }

      setMainSlowConnection(mainConectante.slowConnection);
    },
    [mainConectante],
  );

  function getMainStream() {
    if (mainConectante) {
      return mainConectante.screenStream || mainConectante.videoStream;
    }
    return null;
  }

  function getMainCamEnabled() {
    if (mainConectante && mainConectante.screenStream) {
      return true;
    }

    return mainCamEnabled;
  }

  const [slotPorStreamId, setSlotPorStreamId] = useState(new Map());
  useEffect(
    function calcularGridSize() {
      const slotPorSocketIdETipoDeStream = new Map();

      let ultimoSlot = 0;
      slotPorSocketIdETipoDeStream.set(`${socket.id}_video`, `v${ultimoSlot++}`);

      if (shareScreen) {
        slotPorSocketIdETipoDeStream.set(`${socket.id}_screen`, `v${ultimoSlot++}`);
      }

      conectantesComMediaStream.forEach(c => {
        slotPorSocketIdETipoDeStream.set(`${c.socketId}_video`, `v${ultimoSlot++}`);
        if (c.screenStream) {
          slotPorSocketIdETipoDeStream.set(`${c.socketId}_screen`, `v${ultimoSlot++}`);
        }
      });

      setSlotPorStreamId(slotPorSocketIdETipoDeStream);
    },
    [conectantesComMediaStream, shareScreen],
  );

  function getNextGridSlot(socketId, tipo) {
    return slotPorStreamId.get(`${socketId}_${tipo}`);
  }

  const notificarServeUsuarioComProblemaMicrofone = useCallback(
    tipoDeEvento => {
      const MicProblemParams = {
        token: token,
        userId: localName,
        tipoDeEvento,
      };

      socket.current.emit('MIC_PROBLEM', MicProblemParams);
    },
    [socket, token, localName],
  );

  const forceMicRefresh = useCallback(async () => {
    try {
      const stream = await getWebRTCUserMedia({
        videoDeviceId: devicesState.cameraSelecionada,
        audioDeviceId: devicesState.micSelecionado,
      });
      console.log('forceMicRefresh setMediaStream');
      dispatch(setMediaStream(stream));
    } catch (e) {
      console.log('error forceMicRefresh', e);
    }
  }, [devicesState.cameraSelecionada, devicesState.micSelecionado, dispatch]);

  const [exibirModalTesteMicrofone, setExibirModalTesteMicrofone] = useState(false);

  const [iniciarConexaoPublisher, setIniciarConexaoPublisher] = useState(false);

  useEffect(
    function atualizarIniciarConexaoPublisher() {
      setIniciarConexaoPublisher(conectantes.length > 0);
    },
    [conectantes.length],
  );

  useEffect(
    function iniciarPublishConnection() {
      if (pronto && iniciarConexaoPublisher) {
        negotiator.current.setupPublisherConnection();
      }
    },
    [pronto, iniciarConexaoPublisher],
  );

  useEffect(
    function muteAudioOnStreamWhenMicDisabled() {
      if (activeStream && activeStream.getAudioTracks().length > 0) {
        activeStream.getAudioTracks()[0].enabled = devicesState.micEnabled;
      }
    },
    [activeStream, devicesState.micEnabled],
  );

  useEffect(
    function updatePublishedStream() {
      if (localVideo.current && activeStream && localVideo.current?.srcObject?.id !== activeStream.id) {
        localVideo.current.srcObject = activeStream;
      }
    },
    [activeStream, streamsState.streamChangeCount],
  );

  const onPermissionRevoked = useCallback(() => socket.current.emit('TEM_PERMISSAO_MIC_CAMERA', false), []);

  return (
    <div className={`myRoom ${pronto ? 'ready container-fluid ' + layout : 'container'}`}>
      <header className="header col-md-12 row">
        <div className="leftHeader col-4 py-md-3 py-2 pl-md-5"></div>
        <div className="centerHeader col-4 mt-md-1">
          <div className="roomId text-center">
            <label>Sala:</label> {token}
            <div className="conectados">
              <FontAwesomeIcon icon={faUsers} className="icon me-2" />
              <span className="conectadosLabel"></span>
              <span className="conectadosNumber">{conectantes.length + 1}</span>
            </div>
          </div>

          {pronto && (conectantes.length > 0 || urlParams.hora) && (
            <>
              <ContadorTempo horaInicio={horaInicio} roomToken={token} />{' '}
              <button className="hidden debugStream" onClick={() => setShowStreamDebugger(!showStreamDebugger)}>
                DebugStream
              </button>
            </>
          )}
        </div>
        <div className="rightHeader col-4 mt-md-3">
          {pronto && conectantes.length > 0 && (
            <div className="btnRecordtime">
              <Timer formatValue={formatTime}>
                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
              </Timer>
            </div>
          )}
          <RightHeaderContent setLayout={setLayout} />
        </div>
      </header>
      <section id="body" className={`${toolbarState.showDrawer ? 'drawerOpen' : ''}`}>
        <Toaster toastOptions={{ className: 'centerToaster' }} />
        <ConectandoModal show={showModalConectando}></ConectandoModal>
        <ReconectandoModal show={showModalReconectando}></ReconectandoModal>
        <ConexaoLentaModal show={slowConnection && !showModalConectando && !showModalReconectando}></ConexaoLentaModal>
        <TesteMicrofoneModal show={exibirModalTesteMicrofone} onHide={() => setExibirModalTesteMicrofone(false)} />
        <FatalErrorModal show={showFatalErrorModal} />
        <ExitRoomModal show={showExitRoomModal} onHide={() => setShowExitRoomModal(false)}></ExitRoomModal>
        <StreamVideoDebbuger conectantesComMediaStream={conectantesComMediaStream} show={showStreamDebugger} />

        <Modal show={showModalSelecionarMicCam} onHide={() => setShowModalSelecionarMicCam(false)}>
          <Modal.Header closeButton style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faCog} className="icon" />
            <span className="ms-2">Configurações</span>
          </Modal.Header>
          <Modal.Body>
            <MicCamPicker key="MicCamPickerPopup" />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowModalSelecionarMicCam(false)}>OK</Button>
          </Modal.Footer>
        </Modal>

        <div
          id="mainVideoPlaceholder"
          className={conectantes.length > 0 && streamChangeCount >= 0 && layout !== 'gridLayout' ? '' : 'hidden'}
        >
          {!shareScreen && pronto && (
            <VideoContainerSemRef
              microfoneLigado={mainMicEnabled}
              gridArea="vMain"
              containerClassName="remoteVideoContainer"
              videoClassName=""
              nomeParticipante={mainName}
              reproduzirAudio={false}
              cameraLigada={getMainCamEnabled()}
              mostrarMicFeedback={true}
              videoMediaStream={getMainStream()}
              slowConnection={mainSlowConnection}
              audioMediaStream={shareScreen ? activeStream : mainConectante?.videoStream}
            />
          )}

          {shareScreen && pronto && (
            <VideoContainerSemRef
              microfoneLigado={devicesState.micEnabled}
              gridArea="vMain"
              containerClassName="remoteVideoContainer"
              videoClassName="screenShare"
              nomeParticipante={localName}
              reproduzirAudio={false}
              cameraLigada={true}
              mostrarMicFeedback={true}
              videoMediaStream={myScreenStream.current}
              slowConnection={slowConnection}
              audioMediaStream={shareScreen ? activeStream : mainConectante?.videoStream}
            />
          )}
        </div>

        <div id="participantsPlaceholder">
          {!isMobile && <VirtualBackground />}

          <div className={`videosContainer ${pronto ? `grid grid${slotPorStreamId.size}` : 'col-md-8'}`}>
            {conectantes.length === 0 && <span className="awaitingParticipants">Aguardando participantes...</span>}

            <VideoContainer
              microfoneLigado={devicesState.micEnabled}
              gridArea={getNextGridSlot(socket.id, 'video')}
              containerClassName="remoteVideoContainer"
              videoClassName="flip"
              id={'localVideo-' + token}
              ref={localVideo}
              nomeParticipante={localName}
              reproduzirAudio={false}
              mostrarMicFeedback={true}
              audioMediaStream={activeStream}
              cameraLigada={devicesState.camEnabled}
              slowConnection={slowConnection}
              monitorarProblemasDeMicrofone={pronto}
              shouldZoomOnBigContainer={true}
              pronto={pronto}
              onMicInativo={() => {
                console.log('notificarServeUsuarioComProblemaMicrofone');
                notificarServeUsuarioComProblemaMicrofone('onMicInativo');
                setExibirModalTesteMicrofone(true);
              }}
              onForceMicRefresh={() => {
                notificarServeUsuarioComProblemaMicrofone('onForceMicRefresh');
                forceMicRefresh();
              }}
            />

            {shareScreen && (
              <VideoContainerSemRef
                microfoneLigado={devicesState.micEnabled}
                gridArea={getNextGridSlot(socket.id, 'screen')}
                containerClassName="remoteVideoContainer"
                videoClassName=""
                nomeParticipante={localName}
                reproduzirAudio={false}
                cameraLigada={true}
                mostrarMicFeedback={true}
                videoMediaStream={myScreenStream.current}
                audioMediaStream={activeStream}
                slowConnection={slowConnection}
              />
            )}

            {streamChangeCount >= 0 &&
              conectantesComMediaStream.map((conectante, idx) => (
                <React.Fragment key={conectante.socketId}>
                  <VideoContainerSemRef
                    gridArea={getNextGridSlot(conectante.socketId, 'video')}
                    key={conectante.socketId + '_video'}
                    streamId={conectante.socketId}
                    microfoneLigado={conectante.micEnabled}
                    mostrarMicFeedback={true}
                    containerClassName="remoteVideoContainer"
                    videoClassName={remoteStreamIdQueEstaFalando === conectante.socketId ? 'speaking' : ''}
                    onSpeaking={onVideoSpeaking}
                    slowConnection={conectante.slowConnection}
                    nomeParticipante={conectante.identificacao}
                    reproduzirAudio={true}
                    audioMediaStream={conectante.videoStream}
                    videoMediaStream={conectante.videoStream}
                    shouldZoomOnBigContainer={true}
                    cameraLigada={conectante.camEnabled}
                  />

                  {conectante.screenStream && (
                    <VideoContainerSemRef
                      gridArea={getNextGridSlot(conectante.socketId, 'screen')}
                      key={conectante.socketId + '_screen'}
                      streamId={conectante.socketId}
                      mostrarMicFeedback={false}
                      containerClassName="remoteVideoContainer"
                      nomeParticipante={conectante.identificacao}
                      reproduzirAudio={false}
                      videoMediaStream={conectante.screenStream}
                      cameraLigada={true}
                      slowConnection={conectante.slowConnection}
                    />
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>

        {!pronto && (
          <>
            <Form noValidate validated={errors.length > 0} onSubmit={handleSubmit(entrarNaSala)}>
              {msgErroAoEntrarNaSala != undefined && msgErroAoEntrarNaSala != '' && (
                <FalhaValidacaoEntradaModal
                  show={msgErroAoEntrarNaSala != undefined && msgErroAoEntrarNaSala != ''}
                  messageBundle={msgErroAoEntrarNaSala}
                  showSpinner={isAguardarMedico && !temMedicoNaSala}
                ></FalhaValidacaoEntradaModal>
              )}
              <div className="panelRight col-sm-12 col-md-4 offset-md-8">
                {
                  <MicCamPicker
                    key="MicCamPickerExternal"
                    iconClassName="text-white"
                    onPermissionRevoked={onPermissionRevoked}
                  />
                }

                {!localName && (
                  <Form.Group className="px-4  pt-3">
                    <FormControl
                      autoFocus
                      id="user"
                      name="user"
                      style={{ paddingLeft: '7px', paddingRight: '11px' }}
                      placeholder="Seu Nome *"
                      aria-label="Seu Nome *"
                      isInvalid={errors.user}
                      ref={register({ required: 'Digite seu nome' })}
                      size="sm"
                    />
                  </Form.Group>
                )}

                <div className="d-grid px-4 py-4">
                  {devicesState.hasMicCamPermission && (
                    <Button
                      type="submit"
                      id="ficarPronto"
                      size="lg"
                      disabled={!validacaoRealizada || msgErroAoEntrarNaSala || !activeStream}
                    >
                      Entrar na sala
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </>
        )}
      </section>
      <aside
        id="drawer"
        style={{
          height: 'calc(100vh - var(--footer-height))',
          boxShadow: '5px 5px 20px 2px rgb(0 0 0 / 50%)',
          position: 'fixed',
          right: 0,
          top: 0,
          width: `${toolbarState.drawerWidth}vw`,
          transition: 'width 0.5s ease-in-out',
        }}
      >
        <PsicologiaVivaDrawerContent />
        <img className="prontuarioImg" src={`/clientUploads/${window.location.hostname}/prontuario.png`}></img>
      </aside>

      {pronto && (
        <>
          <div className={`chatContainer ${chatOpen ? '' : 'hidden'}`}>
            <NewChatComponent
              socket={socket.current}
              token={token}
              user={localName}
              close={() => setChatOpen(false)}
              messageReceived={() => setChatOpen(true)}
            ></NewChatComponent>
          </div>

          <section id="footer">
            <Toolbar
              // FIXME -> passar todos esses estados para o redux
              setShowExitRoomModal={setShowExitRoomModal}
              micEnabled={devicesState.micEnabled}
              setMicEnabled={enabled => dispatch(setMicEnabled(enabled))}
              canShareScreen={canShareScreen}
              shareScreen={shareScreen}
              setShareScreen={setShareScreen}
              layout={layout}
              swapLayout={swapLayout}
              setShowModalSelecionarMicCam={setShowModalSelecionarMicCam}
              setChatOpen={setChatOpen}
              chatOpen={chatOpen}
              toggleShareScreen={() => {
                if (!shareScreen) {
                  startScreenShare();
                } else {
                  stopScreenShare();
                }
              }}
            />
          </section>
        </>
      )}
    </div>
  );
};

MyVideoRoomComponent.propTypes = {
  token: PropTypes.string,
  user: PropTypes.string,
  location: PropTypes.object,
};

export default withTranslation('translations')(MyVideoRoomComponent);
