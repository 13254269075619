import React from 'react';
import { Spinner } from 'react-bootstrap';
import NonBlockingModal from '../../modal/NonBlockingModal';

import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';

const ConectandoModal = (props: { show: boolean }) => {
  return (
    <NonBlockingModal show={props.show}>
      <Spinner animation="border" role="status" className="mt-2">
        <span className="sr-only">{i18n.t('Conectando')}</span>
      </Spinner>
      <p>{i18n.t('Conectando')}</p>
    </NonBlockingModal>
  );
};
//@ts-ignore
export default withTranslation('translations')(ConectandoModal);
