import React, { useState, useRef, useEffect, createRef } from 'react';

import './ChatComponent.css';
import icoUser from '../../assets/Portrait_Placeholder.png';
import Linkify from 'react-linkify';
import axios, { AxiosRequestConfig } from 'axios';
import { DropEvent, FileRejection } from 'react-dropzone';

import { useDropzone } from 'react-dropzone';
import { Socket } from 'socket.io-client';
import ShortUniqueId from 'short-unique-id';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPaperclip, faTimesCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';

type ChatComponentProps = {
  user: string;
  socket: Socket;
  token: string;

  messageReceived: Function;
  close: Function;
};

type Message = {
  type: 'MESSAGE' | 'ERROR' | 'FILE' | 'FILE_UPLOAD';
  nickname: string;
  message: string;
  uploadId?: string;
  progress?: number;
  fileName?: string;
  downloadPath?: string;
};

const ChatComponent = (props: ChatComponentProps) => {
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [message, setMessage] = useState('');
  const [progressByUploadId, setProgressByUploadId] = useState<Map<string, number>>(new Map());
  const [showModalLogs, setShowModalLogs] = useState<boolean>();

  const chatScroll = useRef<HTMLDivElement>(null);

  const messageListRef = useRef<Message[]>([]);
  messageListRef.current = messageList;

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
    maxFiles: 1,
    //2MB
    maxSize: 2097152,
  });

  async function onDropAccepted(acceptedFiles: File[]) {
    const messageId = new ShortUniqueId().stamp(16);

    const formData = new FormData();
    acceptedFiles.forEach(f => {
      formData.append('file', f, f.name);
    });
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressByUploadId.set(messageId, percentCompleted);
        setProgressByUploadId(new Map(progressByUploadId));
      },
    };

    setMessageList(
      messageListRef.current.concat({
        type: 'FILE_UPLOAD',
        uploadId: messageId,
        nickname: props.user,
        message: 'Enviando arquivo...',
        progress: 0,
      }),
    );

    try {
      const resp = await axios.post(`/fileUpload/${props.token}/${props.socket.id}`, formData, config);
    } catch (err) {
      messageListRef.current.push({
        type: 'ERROR',
        nickname: props.user,
        //@ts-ignore
        message: `Erro ao realizar upload - ${err.message}`,
      });
    }
  }

  function onDropRejected(fileRejections: Array<FileRejection>, event: DropEvent | Event) {
    fileRejections.forEach(rejection => {
      rejection.errors.forEach(error => {
        let message = error.message;
        if (error.code === 'file-too-large') {
          message = 'Erro: o arquivo é muito grande parar ser enviado. O tamanho máximo é 2MBs.';
        } else if (error.code === 'too-many-files') {
          message = 'Erro: Selecione apenas um arquivo por vez.';
        }
        setMessageList(
          messageListRef.current.concat({
            type: 'ERROR',
            nickname: props.user,
            message: message,
          }),
        );
      });
    });
  }

  useEffect(
    function updateUploadProgress() {
      progressByUploadId.forEach((v, k) => {
        const msg = messageListRef.current.find(m => m.uploadId == k);
        if (msg) {
          msg.progress = v;
        }
      });

      setMessageList(messageListRef.current.concat());
    },
    [progressByUploadId],
  );

  useEffect(
    function setupMessageReceived() {
      const scrollToBottom = () => {
        try {
          if (chatScroll.current) {
            chatScroll.current.scrollTop = chatScroll.current.scrollHeight;
          }
        } catch (err) {}
      };

      scrollToBottom();

      props.socket.off('MENSAGEM_CHAT');

      props.socket.on('MENSAGEM_CHAT', event => {
        console.log('chatmessageReceived', event);

        if (event.type == 'FILE') {
          setMessageList(messageListRef.current.concat(event));
        } else {
          setMessageList(
            messageListRef.current.concat({ type: 'MESSAGE', nickname: event.nickname, message: event.message }),
          );
        }

        props.messageReceived();
        scrollToBottom();
      });
    },
    [messageList, props, chatScroll],
  );

  function handlePressKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      sendMessage();
    }
  }

  function sendMessage() {
    if (message) {
      if (message === '@logs') {
        setShowModalLogs(true);
        //fecha o chat
        props.close();
      } else {
        let localMessage = message.replace(/ +(?= )/g, '');
        if (localMessage !== '' && localMessage !== ' ') {
          const data = {
            message: localMessage,
            nickname: props.user,
            token: props.token,
          };

          props.socket.emit('MENSAGEM_CHAT', JSON.stringify(data));
        }
      }
    }
    setMessage('');
  }

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  return (
    <>
      <Modal show={showModalLogs} onHide={() => setShowModalLogs(false)}>
        <Modal.Body style={{ height: '80vh', overflow: 'auto', overflowWrap: 'anywhere' }}>
          {showModalLogs &&
            //@ts-ignore
            JSON.stringify(console.logs, getCircularReplacer())}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModalLogs(false)}>OK</Button>
        </Modal.Footer>
      </Modal>

      <div id="chatContainer">
        <div id="chatComponent">
          <div id="chatToolbar">
            <span>CHAT</span>
            <FontAwesomeIcon
              id="closeButton"
              icon={faTimesCircle as IconProp}
              className="icon"
              onClick={() => props.close()}
            />
          </div>
          <div className="message-wrap" ref={chatScroll}>
            {messageList.map((message, i) => (
              <div
                key={i}
                id="remoteUsers"
                className={
                  'message' +
                  (message.nickname !== props.user ? ' left' : ' right') +
                  ` type${message.type} ${message.progress == 100 ? 'DONE' : ''}`
                }
              >
                <img src={icoUser} className="icon me-2 user-img" width="16" height="16" />

                <div className="msg-detail">
                  <div className="msg-info">
                    <p> {message.nickname}</p>
                  </div>
                  <div className="msg-content">
                    <span className="triangle" />
                    <p className="text">
                      {message.type == 'FILE' && (
                        <Linkify>
                          <a target="blank" href={message.downloadPath}>
                            {message.fileName}
                          </a>
                        </Linkify>
                      )}

                      {message.type != 'FILE' && (
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          {message.message}
                        </Linkify>
                      )}
                    </p>
                    <div className="uploadProgress">
                      <div className="currentProgress" style={{ width: `${message.progress}%` }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div id="messageInput">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <button type="button" onClick={open} title="Enviar arquivo">
                <FontAwesomeIcon id="attachmentButton" icon={faPaperclip as IconProp} className="icon" />
              </button>
            </div>

            <input
              placeholder="Digite sua mensagem..."
              id="chatInput"
              onChange={e => setMessage(e.target.value)}
              value={message}
              onKeyPress={handlePressKey}
              autoComplete="off"
            />

            <button title="Enviar" id="sendButton" onClick={sendMessage}>
              <FontAwesomeIcon size="lg" icon={faPaperPlane as IconProp} className="icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
