import React from 'react';
import NonBlockingModal from '../../modal/NonBlockingModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faWifi, faSlash } from '@fortawesome/free-solid-svg-icons';

import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import './conexaoLentaModal.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ConexaoLentaModal = (props: { show: boolean }) => {
  return (
    <NonBlockingModal show={props.show} className="conexaoLentaModal">
      <span className="fa-layers fa-fw slowConnection">
        <FontAwesomeIcon
          icon={faWifi as IconProp}
          title={i18n.t('Tentando reconectar')}
          className="icon slowConnection"
        />
        <FontAwesomeIcon
          icon={faSlash as IconProp}
          title={i18n.t('Tentando reconectar')}
          className="icon slowConnection"
        />
      </span>
      <div>
        <p>{i18n.t('Conexão fraca')}</p>
      </div>
    </NonBlockingModal>
  );
};
//@ts-ignore
export default withTranslation('translations')(ConexaoLentaModal);
