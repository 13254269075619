import React, { useState, useEffect, useRef, useCallback } from 'react';

import OutputViewer from '../virtualBackground/OutputViewer';
import useTFLite from './useTFLite';
import { PostProcessingConfig } from './helpers/postProcessingHelper';
import { BackgroundConfig } from './helpers/backgroundHelper';
import { SegmentationConfig } from './helpers/segmentationHelper';
import { SourceConfig, SourcePlayback } from './helpers/sourceHelper';

import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';
import { selectVirtualBackground } from './VirtualBackgroundSlice';
import { selectDevices } from '../my-video-room/DevicesSlice';
import { disableVirtualBackground, enableVirtualBackground, selectStreams } from '../my-video-room/StreamsSlice';

/** inspiração: https://github.com/Volcomix/virtual-background */
const VirtualBackground = () => {
  const streamsState = useAppSelector(selectStreams);
  const virtualBackgroundState = useAppSelector(selectVirtualBackground);
  const dispatch = useAppDispatch();
  const [segmentationConfig, setSegmentationConfig] = useState<SegmentationConfig>({
    model: 'meet',
    inputResolution: '160x96',
    pipeline: 'webgl2',
    targetFps: 30,
    deferInputResizing: true,
  });

  const { tflite, isSIMDSupported } = useTFLite(segmentationConfig);

  const [postProcessingConfig, setPostProcessingConfig] = useState<PostProcessingConfig>({
    smoothSegmentationMask: true,
    jointBilateralFilter: { sigmaSpace: 5, sigmaColor: 0.1 },
    coverage: [0.1, 0.37],
    lightWrapping: 0.3,
    blendMode: 'screen',
  });
  const blurVideoSource = useRef<HTMLVideoElement>(null);

  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>();

  useEffect(() => console.log('isSIMDSupported', isSIMDSupported), [isSIMDSupported]);

  useEffect(
    function setupBlur() {
      if (virtualBackgroundState.enabled && streamsState.mediaStream) {
        blurVideoSource.current!.srcObject = streamsState.mediaStream;

        setSourcePlayback({
          htmlElement: blurVideoSource.current!,
          width: 640,
          height: 480,
        });
      }
      return () => {
        dispatch(disableVirtualBackground());
      };
    },
    [streamsState.mediaStream, virtualBackgroundState.enabled, dispatch],
  );

  const ejectCanvasTreatment = useCallback(
    (ejectedCanvas: React.MutableRefObject<HTMLCanvasElement>) => {
      const blurredStream = ejectedCanvas.current.captureStream(24);
      blurredStream.addTrack(streamsState.mediaStream!.getAudioTracks()[0]);
      dispatch(enableVirtualBackground(blurredStream));
    },
    [dispatch, streamsState.mediaStream],
  );

  return (
    <>
      <video
        className="blurVideoSource"
        playsInline
        autoPlay
        muted={true}
        ref={blurVideoSource}
        style={{
          /** O video precisa estar na viewport e com display block para conseguirmos pegar os frames */
          position: 'fixed',
          left: 0,
          top: 0,
          display: 'block',
          visibility: 'hidden',
        }}
      />

      {virtualBackgroundState.enabled && sourcePlayback && (
        <OutputViewer
          ejectCanvas={ejectCanvasTreatment}
          sourcePlayback={sourcePlayback}
          backgroundConfig={{
            type: virtualBackgroundState.type,
            url: `${virtualBackgroundState.image}`,
          }}
          segmentationConfig={segmentationConfig}
          postProcessingConfig={postProcessingConfig}
          tflite={tflite!}
        />
      )}
    </>
  );
};
//@ts-ignore
export default VirtualBackground;
