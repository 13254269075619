import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

import { CustomerComponentProps } from './CustomerComponentProps';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAppDispatch, useAppSelector } from '../../../infra/redux/hooks';
import { selectJoinRoom } from '../JoinRoomSlice';
import { enableVBGImage } from '../../virtualBackground/VirtualBackgroundSlice';

const roleCliente = 'cUk8aNEque6RrBGQ';
const roleProfissional = 'D7bV2zdYgRQXjxJF';
const dominioCliente = 'sebraego';

export function SebraeGOButtons() {
  const joinRoomState = useAppSelector(selectJoinRoom);

  if (
    window.location.href.includes(dominioCliente) &&
    joinRoomState.urlParams.role &&
    joinRoomState.urlParams.role == roleProfissional
  ) {
    return (
      <span className="btn-circle-container chatBtnContainer">
        <button
          className={`btn-circle btn-md enabled`}
          title="Reg. Atendimento"
          onClick={() =>
            window.open(
              `http://homolog-agendamento.sebraego.com.br/SebraeGoiasProjetoAgendamento/observacaoConsultoria.jsp?uuid=${joinRoomState.token}`,
              '_blank',
            )
          }
        >
          <FontAwesomeIcon icon={faFileAlt as IconProp} className={'icon'} />
        </button>
        <label>Reg. Atend.</label>
      </span>
    );
  }
  return null;
}

export function SebraeGOBotaoAvaliacao() {
  const joinRoomState = useAppSelector(selectJoinRoom);

  if (
    window.location.href.includes(dominioCliente) &&
    joinRoomState.urlParams.role &&
    joinRoomState.urlParams.role == roleCliente
  ) {
    return (
      <Button
        type="button"
        variant="primary"
        onClick={() => {
          window.location.href = `https://${dominioCliente}.connsult.com.br/Avaliacao/avaliar?id=${joinRoomState.token}`;
        }}
      >
        Sair e Avaliar Consultoria
      </Button>
    );
  }
  return null;
}

export function SebraeGOVirtualBackgrounds() {
  const joinRoomState = useAppSelector(selectJoinRoom);
  const dispatch = useAppDispatch();

  if (window.location.href.includes(dominioCliente)) {
    return (
      <>
        <Card
          key={`customerBG1`}
          className="effectItem"
          onClick={() =>
            dispatch(
              enableVBGImage({
                image: `/clientUploads/${window.location.hostname}/sebrae-vbg-1.webp`,
              }),
            )
          }
        >
          <img src={`/clientUploads/${window.location.hostname}/sebrae-vbg-1.webp`} width="150" height="84" />
        </Card>
      </>
    );
  }

  return null;
}
