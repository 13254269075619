import React, { useEffect } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import i18n from '../i18n/i18n';

const PageExitRoom = (props: any) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <Col md="12" className="mt-5">
          <Row>
            <Card className="col-md-4 offset-md-4 text-center">
              <Card.Body>
                <Card.Title>{i18n.t('Você saiu da sala')}</Card.Title>
                <Card.Text>{i18n.t('Obrigado por acessar!')}</Card.Text>
              </Card.Body>
              <Card.Footer style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {i18n.t('Voltar para a sala')}
                </Button>
              </Card.Footer>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PageExitRoom;
