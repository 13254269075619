import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationTriangle, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Spinner } from 'react-bootstrap';

const FalhaValidacaoEntradaModal = (props: { show: boolean; messageBundle: string; showSpinner: boolean }) => {
  return (
    <Modal show={props.show} onHide={() => {}}>
      <Modal.Header>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle as IconProp} className="icon me-1 text-danger" />
          {i18n.t(`${props.messageBundle}.title`).includes('.title')
            ? i18n.t(`Aviso`)
            : i18n.t(`${props.messageBundle}.title`)}
        </span>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
        <p>{i18n.t(props.messageBundle)}</p>
        {props.showSpinner && (
          <>
            <Spinner style={{ alignSelf: 'center' }} animation="border" role="status" className="mb-2">
              <span className="sr-only">{i18n.t('aguarde')}</span>
            </Spinner>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
//@ts-ignore
export default withTranslation('translations')(FalhaValidacaoEntradaModal);
