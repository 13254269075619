import DetectRTC from 'detectrtc';

const videoResolution = {
  width: 640,
  height: 480,
};

export function getBlackSilenceStream() {
  console.log('returning black silence  stream');

  let silence = () => {
    let ctx = new AudioContext(),
      oscillator = ctx.createOscillator();

    const silenceStream = ctx.createMediaStreamDestination().stream.getAudioTracks()[0];

    return Object.assign(silenceStream, { enabled: false });
  };

  let black = () => {
    let canvas = Object.assign(document.createElement('canvas'), {
      width: videoResolution.width,
      height: videoResolution.height,
    });
    //@ts-ignore
    canvas.getContext('2d').fillRect(0, 0, videoResolution.width, videoResolution.height);
    let stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: false });
  };

  return new MediaStream([black(), silence()]);
}

function getVideoConstraints(videoDeviceId?: string) {
  // Nao definimos o min/max pois o firefox nao faz downscaling, o que causa OverConstrainedError
  const defaultConstraints: MediaTrackConstraints = {
    frameRate: { ideal: 24 },
    width: { ideal: videoResolution.width },
    height: { ideal: videoResolution.height },
    //facingMode: { ideal: 'user' },
  };
  if (videoDeviceId) {
    defaultConstraints.deviceId = { exact: videoDeviceId };
  }

  //assumimos que tem cam se for webview por ser mobile
  return DetectRTC.hasWebcam || (window as any).webview ? defaultConstraints : false;
}

function getAudioConstraints(audioDeviceId?: string) {
  const defaultConstraints: MediaTrackConstraints = {
    echoCancellation: { ideal: true },
    autoGainControl: { ideal: true },
    noiseSuppression: { ideal: true },
  };

  if (audioDeviceId) {
    defaultConstraints.deviceId = { exact: audioDeviceId };
  }

  //assumimos que tem mic se for webview por ser mobile
  return DetectRTC.hasMicrophone || (window as any).webview ? defaultConstraints : false;
}

export function getWebRTCUserMedia(devices: {
  withoutVideo?: boolean;
  videoDeviceId?: string;
  audioDeviceId?: string;
}) {
  const constraints = {
    video: devices.withoutVideo ? false : getVideoConstraints(devices.videoDeviceId),
    audio: getAudioConstraints(devices.audioDeviceId),
  };
  console.log('getUserMedia constraints', constraints);

  console.log('navigator media devices', navigator.mediaDevices != undefined);
  return navigator.mediaDevices.getUserMedia(constraints);
}
