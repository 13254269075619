import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import devicesSlice from '../../components/my-video-room/DevicesSlice';
import joinRoomSlice from '../../components/my-video-room/JoinRoomSlice';
import streamSlice from '../../components/my-video-room/StreamsSlice';
import toolbarSlice from '../../components/my-video-room/ToolbarSlice';
import virtualBackgroundSlice from '../../components/virtualBackground/VirtualBackgroundSlice';

export const reduxStore = configureStore({
  reducer: {
    joinRoom: joinRoomSlice,
    virtualBackground: virtualBackgroundSlice,
    devices: devicesSlice,
    streams: streamSlice,
    toolbar: toolbarSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['streams/setMediaStream', 'streams/enableVirtualBackground'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['streams.mediaStream', 'streams.virtualBackgroundStream'],
      },
    }),
});

export type AppDispatch = typeof reduxStore.dispatch;
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type ReduxStoreType = typeof reduxStore;
