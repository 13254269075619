import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import NonBlockingModal from '../../modal/NonBlockingModal';

import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import { io } from 'socket.io-client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ReconectandoModal = (props: { show: boolean }) => {
  return (
    <NonBlockingModal
      show={props.show}
      header={
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle as IconProp} className="text-danger" />
          <span className="ms-2">
            {i18n.t('Aviso')} - {i18n.t('Queda de conexão')}
          </span>
        </div>
      }
    >
      <Spinner animation="border" role="status" className="mt-2">
        <span className="sr-only">{i18n.t('Tentando reconectar')}</span>
      </Spinner>
      <p>{i18n.t('Tentando reconectar')}</p>
    </NonBlockingModal>
  );
};
//@ts-ignore
export default withTranslation('translations')(ReconectandoModal);
