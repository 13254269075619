import React, { useState, useLayoutEffect } from 'react';

import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import './JoinRoom.scss';
import ShortUniqueId from 'short-unique-id';
import { useNavigate } from 'react-router-dom';

const JoinRoomComponent = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, errors } = useForm();

  function onSubmit(data: any) {
    navigate(`/room/${data.roomId}?user=${data.user}`);
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="login-form col-md-3">
          <Form noValidate validated={errors.length > 0} onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roomId">{i18n.t('Código da Sala')}</Form.Label>
              <Form.Control
                type="text"
                id="roomId"
                name="roomId"
                plaintext
                readOnly
                defaultValue={new ShortUniqueId({ length: 16 })()}
                isInvalid={errors.roomId}
                ref={register({ required: i18n.t('O id da sala é obrigatório') })}
              />
              {errors.roomId && <Form.Control.Feedback type="invalid">{errors.roomId.message}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label htmlFor="user">{i18n.t('Seu nome')}</Form.Label>

              <Form.Control
                type="text"
                id="user"
                name="user"
                isInvalid={errors.user}
                ref={register({ required: i18n.t('O nome de usuário é obrigatório') })}
              />
              {errors.user && <Form.Control.Feedback type="invalid">{errors.user.message}</Form.Control.Feedback>}
            </Form.Group>

            <div className="d-grid gap-2">
              <Button type="submit">{i18n.t('Entrar')}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(JoinRoomComponent);
