import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { isSafari } from 'react-device-detect';
import blur from '../../assets/blurBackground_white.png';
import './VirtualBackgroundMenu.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';
import { disableVBG, enableVBGBlur, enableVBGImage, selectVirtualBackground } from './VirtualBackgroundSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBraille, faPortrait } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import i18n from '../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import { SebraeGOVirtualBackgrounds } from '../my-video-room/customerComponents/SebraeGO';
import { SebraeMGVirtualBackgrounds } from '../my-video-room/customerComponents/SebraeMG';

interface VirtualBackground {
  path: string;
  thumbnailPath: string;
}

const VirtualBackgroundMenu = function() {
  const dispatch = useAppDispatch();

  const virtualBackgroundState = useAppSelector(selectVirtualBackground);

  const [backgroundPaths] = useState(() => {
    const backgrounds: VirtualBackground[] = [];
    for (let index = 1; index < 8; index++) {
      backgrounds.push({
        path: `${process.env.PUBLIC_URL}/virtualBackgrounds/720/bg${index}.jpg`,
        thumbnailPath: `${process.env.PUBLIC_URL}/virtualBackgrounds/thumbnails/bg${index}.jpg`,
      });
    }
    return backgrounds;
  });
  const setVirtualBackground = (imagePath: string) => {
    dispatch(
      enableVBGImage({
        image: imagePath,
      }),
    );
  };

  const disableVirtualBackground = () => {
    dispatch(disableVBG({}));
  };

  const enableBackgroundBlur = () => {
    dispatch(enableVBGBlur({}));
  };

  const [customerImageExists, setCustomerImageExists] = useState(false);
  const customerBackgroundImage = useRef<HTMLImageElement>();

  const customerVirtualBackgroundURL = `/clientUploads/${window.location.hostname}/virtualBackground.jpg`;

  useEffect(
    function checkImage() {
      var img = new Image();
      img.onload = () => setCustomerImageExists(true);
      img.onerror = () => setCustomerImageExists(false);
      img.src = customerVirtualBackgroundURL;
      customerBackgroundImage.current = img;
    },
    [customerVirtualBackgroundURL],
  );

  if (isSafari) return null;

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={'top'}
      overlay={
        <Popover id="popoverEffects">
          <div className="backgroundsThumbsContainer">
            {backgroundPaths.map((value, idx) => {
              return (
                <Card key={`bg${idx}`} className="effectItem" onClick={() => setVirtualBackground(value.path)}>
                  <Card.Img src={value.thumbnailPath}></Card.Img>
                </Card>
              );
            })}

            {customerImageExists && (
              <Card
                key={`customerBG`}
                className="effectItem"
                onClick={() => setVirtualBackground(customerVirtualBackgroundURL)}
              >
                <img src={customerVirtualBackgroundURL} width="150" height="84" />
              </Card>
            )}

            <SebraeGOVirtualBackgrounds />
            <SebraeMGVirtualBackgrounds />

            <Card key={`bgBlur`} className="effectItem" onClick={enableBackgroundBlur}>
              <FontAwesomeIcon icon={faBraille as IconProp} size="1x" className={'icon'} />
              {i18n.t('Desfocar')}
            </Card>

            <Card key={`bgOFF`} className="effectItem turnOff" onClick={disableVirtualBackground}>
              <FontAwesomeIcon icon={faPortrait as IconProp} size="1x" className={'icon'} />
              {i18n.t('Sem efeitos')}
            </Card>
          </div>
        </Popover>
      }
    >
      <span className="btn-circle-container effectsBtnContainer">
        <button className={`btn-circle btn-md ${virtualBackgroundState.enabled ? 'active' : 'enabled'}`} title="Blur">
          <FontAwesomeIcon icon={faPortrait as IconProp} className={'icon'} />
        </button>
        <label> {i18n.t('Efeitos')}</label>
      </span>
    </OverlayTrigger>
  );
};

export default withTranslation('translations')(VirtualBackgroundMenu);
