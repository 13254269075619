import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infra/redux/reduxStore';

export interface JoinRoomState {
  token: string;
  urlParams: any;
  customerRoomData?: any;
}

const initialState: JoinRoomState = {
  token: '',
  urlParams: new Map(),
};

export const joinRoomSlice = createSlice({
  name: 'joinRoom',
  initialState,
  reducers: {
    joinRoom: (currentState, payload: PayloadAction<JoinRoomState>) => ({
      token: payload.payload.token,
      urlParams: payload.payload.urlParams,
    }),
    setCustomerParams: (currentState, payload: PayloadAction<{ customerRoomData: any }>) => ({
      token: currentState.token,
      urlParams: currentState.urlParams,
      customerRoomData: payload.payload.customerRoomData,
    }),
  },
});

export const { joinRoom, setCustomerParams } = joinRoomSlice.actions;

export const selectJoinRoom = (state: RootState) => state.joinRoom;

export default joinRoomSlice.reducer;
