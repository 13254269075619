import React, { ComponentType } from 'react';
import './nonBlockingModal.scss';

type ModalProps = {
  show: boolean;
  children: JSX.Element[] | JSX.Element;
  header?: JSX.Element[] | JSX.Element;
  className?: string;
};

const NonBlockingModal = (props: ModalProps) => {
  const className = props.className || '';
  return (
    <>
      {props.show && (
        <div className={`warning-container nonBlockingModal ${className}`}>
          {props.header && <div className="modal-header">{props.header}</div>}

          <div className="warning-body">{props.children}</div>
        </div>
      )}
    </>
  );
};
export default NonBlockingModal;
