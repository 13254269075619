import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../infra/redux/hooks';
import { RootState } from '../../infra/redux/reduxStore';
import PubSubWebRTCNegotiator from './PubSubWebRTCNegotiator';

export interface DevicesState {
  mediaStream?: MediaStream;
  virtualBackgroundStream?: MediaStream;
  useVirtualBackground: boolean;
  streamChangeCount: number;
}

const initialState: DevicesState = {
  streamChangeCount: 0,
  useVirtualBackground: false,
};

export const streamSlice = createSlice({
  name: 'streams',
  initialState,
  reducers: {
    setMediaStream: (currentState, payload: PayloadAction<MediaStream>) => {
      if (currentState.mediaStream && currentState.mediaStream.id !== payload.payload.id) {
        currentState.mediaStream.getTracks().forEach(track => track.stop());
      }

      return {
        ...currentState,
        streamChangeCount: currentState.streamChangeCount + 1,
        mediaStream: payload.payload,
      };
    },
    enableVirtualBackground: (currentState, payload: PayloadAction<MediaStream>) => {
      return {
        ...currentState,
        streamChangeCount: currentState.streamChangeCount + 1,
        useVirtualBackground: true,
        virtualBackgroundStream: payload.payload,
      };
    },
    disableVirtualBackground: currentState => {
      return {
        ...currentState,
        virtualBackgroundStream: undefined,
        useVirtualBackground: false,
      };
    },
  },
});

export const { setMediaStream, disableVirtualBackground, enableVirtualBackground } = streamSlice.actions;

export const selectStreams = (state: RootState) => state.streams;

export default streamSlice.reducer;

export function useActiveStream() {
  const streamsState = useAppSelector(selectStreams);
  const [streamAtiva, setStreamAtiva] = useState<MediaStream>();

  useEffect(() => {
    setStreamAtiva(streamsState.useVirtualBackground ? streamsState.virtualBackgroundStream : streamsState.mediaStream);
  }, [streamsState.mediaStream, streamsState.useVirtualBackground, streamsState.virtualBackgroundStream]);

  return streamAtiva;
}

export function useStreamPublishManager(negotiator: React.MutableRefObject<PubSubWebRTCNegotiator>) {
  const activeStream = useActiveStream();

  useEffect(
    function updatePublishedStream() {
      if (activeStream) {
        console.log('publishing new stream');
        negotiator.current.replacePublisherStream(activeStream);
      }
    },
    [activeStream, negotiator],
  );
}
