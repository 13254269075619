import axios from 'axios';
import { Socket } from 'socket.io-client';
import { reduxStore } from '../../infra/redux/reduxStore';
import { getBlackSilenceStream } from './MediaDevices';
interface PeerConnectionCallbacks {
  onConnecting: (...args: any[]) => void | undefined;
  onConnected: (...args: any[]) => void | undefined;
  onDisconnected: (...args: any[]) => void | undefined;
  onRemoteStream: (...args: any[]) => void;
  onFatalError: (...args: any[]) => void;
}

interface SessionDescriptionDestination {
  publishId?: number;
}

interface SessionDescriptionOrigin {
  publishId: number;
  publisherSocketId: string;
  tipo: 'video' | 'screen';
}

interface IceCandidateInfo {
  type: 'video-publisher' | 'screen-publisher' | 'subscriber';
  subscriberSocketId: string;
  publisherSocketId: string;
  publishId?: number;
}

export default class PubSubWebRTCNegotiator {
  socket: Socket;
  callbacks: PeerConnectionCallbacks;
  publisherPeerConnection!: RTCPeerConnection;
  screenSharePeerConnection?: RTCPeerConnection;
  videoSender?: RTCRtpSender;
  audioSender?: RTCRtpSender;
  turnServer?: any;
  timerParaRefazerPublishConnection?: NodeJS.Timeout | null;
  offersWithoutAnswer: number = 0;

  subscribersPeerConnetionPerPublishId: Map<string, RTCPeerConnection>;

  constructor(socket: Socket, callbacks: PeerConnectionCallbacks) {
    //essa chamada é necessária pois na primeira offer no chrome ele não gera os candidatos do turnserver.
    //Ainda não descobri o motivo. Se o cliente não consegue se conectar sem o turnserver nosso
    //mecanismo de reconexão resolve pois misticamente na segunda chamada funciona...
    //no firefox já funciona de primeira sem essa chamada inicial.
    this.getRTCConfiguration();

    this.socket = socket;
    this.callbacks = callbacks;
    this.subscribersPeerConnetionPerPublishId = new Map();

    // @ts-ignore
    window.negotiator = this;
  }

  limparPeerConnection(connection?: RTCPeerConnection) {
    if (!connection) return;
    if (this.timerParaRefazerPublishConnection) {
      clearTimeout(this.timerParaRefazerPublishConnection);
      this.timerParaRefazerPublishConnection = null;
    }

    connection.onconnectionstatechange = null;
    connection.onnegotiationneeded = null;
    connection.onicecandidate = null;
    connection.onicecandidateerror = null;
    connection.ondatachannel = null;
    connection.onicegatheringstatechange = null;
    connection.onsignalingstatechange = null;
    connection.oniceconnectionstatechange = null;

    connection.ontrack = null;
    this.turnServer = null;
    this.getRTCConfiguration();

    try {
      connection.close();
    } catch (e) {}
  }

  async getRTCConfiguration(): Promise<RTCConfiguration> {
    const iceServers = [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
    ];

    if (!this.turnServer) {
      try {
        this.turnServer = await (await axios.create().get('/turnServer')).data;
      } catch (e) {
        console.log('erro ao obter turn server', e);
      }
    } else {
      iceServers.push(this.turnServer);
    }

    return {
      iceServers,
    };
  }

  /**
   * Isso ocorre quando não é possível estabelecer a conexão por problemas de não encontrar um iceCandidate adequado.
   * Neste cenário é necessário retentar a conexão.
   */
  deuProblemaComIceCandidate(connection: RTCPeerConnection) {
    const deuProblemaComIceCandidate =
      connection.signalingState == 'stable' &&
      connection.connectionState == 'new' &&
      connection.iceGatheringState == 'complete';
    console.log('Deu problema com ice candidate', deuProblemaComIceCandidate, {
      signalingState: connection.signalingState,
      connectionState: connection.connectionState,
      iceGatheringState: connection.iceGatheringState,
    });
    return deuProblemaComIceCandidate;
  }

  configurarReconexaoPorProblemaDeIce(connection: RTCPeerConnection, reconnectFunction: Function) {
    const reconectarSeDeuProblemaDeIce = () => {
      if (this.deuProblemaComIceCandidate(connection)) {
        reconnectFunction();
      }
    };

    connection.onicegatheringstatechange = () => {
      //@ts-ignore
      if (connection.reconectTimeout) {
        //@ts-ignore
        clearTimeout(connection.reconectTimeout);
      }
      //@ts-ignore
      connection.reconectTimeout = setTimeout(reconectarSeDeuProblemaDeIce, 5000);
    };

    connection.onsignalingstatechange = () => {
      //@ts-ignore
      if (connection.reconectTimeout) {
        //@ts-ignore
        clearTimeout(connection.reconectTimeout);
      }
      //@ts-ignore
      connection.reconectTimeout = setTimeout(reconectarSeDeuProblemaDeIce, 5000);
    };
  }

  async setupPublisherConnection() {
    if (
      this.publisherPeerConnection == null ||
      this.publisherPeerConnection.connectionState === 'failed' ||
      this.publisherPeerConnection.connectionState === 'closed' ||
      this.publisherPeerConnection.connectionState === 'new'
    ) {
      this.limparPeerConnection(this.publisherPeerConnection);

      this.publisherPeerConnection = new RTCPeerConnection(await this.getRTCConfiguration());

      console.log('criei nova publisherPeerConnection');

      this.socket.off('negotiation:sessionDescription');
      this.socket.on('negotiation:sessionDescription', this.onSessionDescriptionReceived.bind(this));

      this.socket.off('negotiation:screenshareSessionDescriptionAnswer');
      this.socket.on('negotiation:screenshareSessionDescriptionAnswer', this.onScreenShareAnswer.bind(this));

      this.socket.off('negotiation:iceCandidate');
      this.socket.on('negotiation:iceCandidate', this.onIceCandidateReceived.bind(this));

      this.socket.off('FATAL_ERROR');
      this.socket.on('FATAL_ERROR', this.callbacks.onFatalError);

      this.socket.off('CONECTANTE_REMOVIDO');
      this.socket.on('CONECTANTE_REMOVIDO', (socketId: string) => {
        console.log('recebi um conectante removido', socketId);
        this.subscribersPeerConnetionPerPublishId.forEach((v, k) => {
          if (k.includes(socketId)) {
            v.close();
            //@ts-ignore preciso disparar na mão pq a spec do webrtc diz que o close não deve disparar eventos de change O.o
            v.onconnectionstatechange();
          }
        });
      });

      this.timerParaRefazerPublishConnection = setTimeout(() => {
        //360 offers são 30 minutos enviando offer sem response.
        const limiteOffersSemAnswer = 360;
        if (this.offersWithoutAnswer > limiteOffersSemAnswer) {
          //Desconectamos o usuário pois provavelmente ele está AFK
          window.location.href = '/exit';
        }

        if (this.publisherPeerConnection && this.publisherPeerConnection.signalingState === 'have-local-offer') {
          console.log(
            `signalingState === 'have-local-offer' depois de 5s. Invocando setupPublisherConnection novamente, retry number: ${this.offersWithoutAnswer}`,
          );
          this.setupPublisherConnection();
          this.offersWithoutAnswer++;
        } else {
          if (this.timerParaRefazerPublishConnection) {
            this.offersWithoutAnswer = 0;
            clearTimeout(this.timerParaRefazerPublishConnection);
            this.timerParaRefazerPublishConnection = null;
          }
        }
      }, 5000);

      this.publisherPeerConnection.onnegotiationneeded = async () => {
        try {
          await this.publisherPeerConnection.setLocalDescription(await this.publisherPeerConnection.createOffer());
          console.log('onnegotiationneeded enviei Offer', this.publisherPeerConnection.localDescription);
          this.sendDescription(this.publisherPeerConnection.localDescription!);
        } catch (err) {
          console.error(err);
        }
      };

      this.configurarReconexaoPorProblemaDeIce(this.publisherPeerConnection, () => {
        console.log('Reiniciando publicação devido problema de Ice Candidate');
        this.subscribersPeerConnetionPerPublishId.forEach((v, k) => {
          this.encerrarSubscribeSemForcarReconexao(k);
        });

        this.setupPublisherConnection();
      });

      this.publisherPeerConnection.onconnectionstatechange = () => {
        console.log('publisher onconnectionstatechange', this.publisherPeerConnection.connectionState);

        if (this.publisherPeerConnection.connectionState === 'connecting') {
          if (this.callbacks.onConnecting) {
            this.callbacks.onConnecting();
          }
        }
        if (this.publisherPeerConnection.connectionState === 'connected') {
          if (this.callbacks.onConnected) {
            this.registrarConexaoEstabelecida();
            this.callbacks.onConnected();
          }
        } else if (this.publisherPeerConnection.connectionState === 'disconnected') {
          if (this.callbacks.onDisconnected) {
            this.callbacks.onDisconnected();
          }
        } else if (
          this.publisherPeerConnection.connectionState === 'failed' ||
          this.publisherPeerConnection.connectionState === 'closed'
        ) {
          if (this.callbacks.onDisconnected) {
            this.callbacks.onDisconnected();
          }
          this.socket.emit('PUBLISH_ENDED', {
            connectionStatus: this.publisherPeerConnection.connectionState,
            screenShare: false,
          });
          this.setupPublisherConnection();
        }
      };

      this.publisherPeerConnection.onicecandidate = ({ candidate }) => {
        const iceInfo: IceCandidateInfo = {
          type: 'video-publisher',
          publisherSocketId: this.socket.id,
          subscriberSocketId: 'irrelevante por enquanto, vai deixar de ser quando tiver N x N p2p',
        };
        console.log('publisherPeer emitingIceCandidate', iceInfo, candidate);
        this.socket.emit('negotiation:iceCandidate', candidate, iceInfo);
      };

      this.publisherPeerConnection.oniceconnectionstatechange = ev => {
        console.log('publisher connection state change', this.publisherPeerConnection.iceConnectionState);
      };

      const stream = reduxStore.getState().streams.useVirtualBackground
        ? reduxStore.getState().streams.virtualBackgroundStream
        : reduxStore.getState().streams.mediaStream;

      const blackSilence = getBlackSilenceStream();

      this.videoSender = this.publisherPeerConnection.addTrack(blackSilence.getVideoTracks()[0], blackSilence!);
      this.audioSender = this.publisherPeerConnection.addTrack(blackSilence.getAudioTracks()[0], blackSilence!);

      if (stream) this.replacePublisherStream(stream);
    } else {
      console.log(
        'setupPeerConnection abortado devido estado da publisherPeerConnection',
        this.publisherPeerConnection?.connectionState,
      );
    }
  }

  async registrarConexaoEstabelecida() {
    //@ts-ignore
    this.socket.emit('CONEXAO_ESTABELECIDA', this.publisherPeerConnection.publishId);

    /**
     * Devido ao processo de trickle, a primeira conexão estabelecida não é a definitiva.
     * O processo de conexão estabelece com o primeiro candidato viável. Boa parte das vezes é via TURN.
     * Mas em seguida, recebe um candidato STUN que é viável, e migra o tipo de conexão.
     * Faço o registro 15 segundos depois pois todos candidatos já foram recebidos e a conexão rodando é definitiva.
     */
    setTimeout(async () => {
      const stats = await this.publisherPeerConnection.getStats();
      const allStats = [...stats.values()];
      const candidatepair = allStats.find(s => s.type === 'candidate-pair' && s.nominated);

      if (candidatepair) {
        const candidate = allStats.find(s => s.id === candidatepair.localCandidateId);

        this.socket.emit('TIPO_CONEXAO', {
          tipoConexao: candidate.candidateType,
          candidate: candidate,
        });
      }
    }, 15000);
  }

  async shareScreen(callbacks: { onVideoTrackEnded: { (this: MediaStreamTrack, ev: Event): any } }) {
    //@ts-ignore
    const displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: { frameRate: { ideal: 10, max: 10 } },
      audio: true,
    });

    const videoTrack = displayMediaStream.getVideoTracks()[0];
    videoTrack.onended = callbacks.onVideoTrackEnded;

    this.limparPeerConnection(this.screenSharePeerConnection);

    this.screenSharePeerConnection = new RTCPeerConnection(await this.getRTCConfiguration());
    await this.screenSharePeerConnection.addTrack(videoTrack);

    this.screenSharePeerConnection.onnegotiationneeded = async () => {
      try {
        await this.screenSharePeerConnection!.setLocalDescription(await this.screenSharePeerConnection!.createOffer());
        console.log('enviei Offer sharescreen', this.screenSharePeerConnection!.localDescription);
        this.socket.emit(
          'negotiation:screenshareSessionDescription',
          this.screenSharePeerConnection!.localDescription,
          { publisherSocketId: this.socket.id },
        );
      } catch (err) {
        console.error(err);
      }
    };

    this.screenSharePeerConnection.onconnectionstatechange = () => {
      console.log(`screenShare PeerConnection stateChange: ${this.screenSharePeerConnection!.connectionState}`);
      if (
        this.screenSharePeerConnection &&
        (this.screenSharePeerConnection.connectionState === 'failed' ||
          this.screenSharePeerConnection.connectionState === 'closed')
      ) {
        this.socket.emit('PUBLISH_ENDED', {
          connectionStatus: this.screenSharePeerConnection.connectionState,
          screenShare: true,
        });
      }
    };

    this.screenSharePeerConnection.onicecandidate = ({ candidate }) => {
      const iceInfo: IceCandidateInfo = {
        type: 'screen-publisher',
        publisherSocketId: this.socket.id,
        subscriberSocketId: 'irrelevante por enquanto, vai deixar de ser quando tiver N x N p2p',
      };
      /* console.log('emiting screenshare IceCandidate', iceInfo, candidate); */
      this.socket.emit('negotiation:iceCandidate', candidate, iceInfo);
    };

    return displayMediaStream;
  }

  async stopScreenShare() {
    if (this.screenSharePeerConnection) {
      this.screenSharePeerConnection.close();
      //@ts-ignore - Invoco na mão pois quando chamamos o close o eventHandler não é acionado.
      this.screenSharePeerConnection.onconnectionstatechange();
    }
  }

  async onScreenShareAnswer(description: RTCSessionDescription) {
    console.log('recebi screenShare answer', description);
    if (this.screenSharePeerConnection) {
      await this.screenSharePeerConnection.setRemoteDescription(description);
    }
  }

  async onSessionDescriptionReceived(description: RTCSessionDescription, origin: SessionDescriptionOrigin) {
    console.log('sessionDescriptionReceived:', description);

    if (description.type === 'offer') {
      await this.criarSubscriberPeerConnection(origin, description);
    } else if (
      (!description.type || description.type === 'answer') &&
      this.publisherPeerConnection.signalingState !== 'stable'
    ) {
      //@ts-ignore
      this.publisherPeerConnection.publishId = origin.publishId;
      try {
        console.log('settingRemoteDescription', description);
        await this.publisherPeerConnection.setRemoteDescription(description);
      } catch (err) {
        console.log('error setting remote description.', err);
      }
    } else {
      console.log('description.type', description.type);
      console.log('this.publisherPeerConnection.signalingState', this.publisherPeerConnection?.signalingState);
    }
  }

  replacePublisherStream(stream: MediaStream) {
    console.log('replacePublisherStream videoSender', this.videoSender);
    console.log('replacePublisherStream new videoTracks', stream.getVideoTracks()[0]);

    if (this.videoSender) {
      this.videoSender.replaceTrack(stream.getVideoTracks()[0] || getBlackSilenceStream().getVideoTracks()[0]);
    }
    if (this.audioSender) {
      this.audioSender.replaceTrack(stream.getAudioTracks()[0]);
    }
  }

  async criarSubscriberPeerConnection(origin: SessionDescriptionOrigin, description: RTCSessionDescription) {
    console.log('criando subscriberPeerConnection', origin);
    const subscriberPeerConnection = new RTCPeerConnection(await this.getRTCConfiguration());

    const subscribeConnectionKey = `${origin.publisherSocketId}::::${origin.publishId!}`;
    const conexaoExistente = this.subscribersPeerConnetionPerPublishId.get(subscribeConnectionKey);
    if (
      conexaoExistente &&
      (conexaoExistente.connectionState == 'connected' || conexaoExistente.connectionState == 'connecting')
    ) {
      console.log(`encerrando subscriberPeerConnection existente ${subscribeConnectionKey}`);
      this.encerrarSubscribeSemForcarReconexao(subscribeConnectionKey);
    }
    this.subscribersPeerConnetionPerPublishId.set(subscribeConnectionKey, subscriberPeerConnection);

    subscriberPeerConnection.ontrack = ({ track, streams }) => {
      console.log('entrei no ontrack do subscriberPeerConnection', track, streams);

      /* Estou invocando tanto para track de audio quanto de video 
      pois nunca sabemos quais tipos estarão disponíveis. 
      Em screenShare é só video. Em usuário sem camera é só audio.
      No cenário mais comum vem as duas tracks e o onRemoteStream é invocado duas vezes,
      O que não é o ideial mas é necessário para não prejudicar os outros cenários.
      */
      this.callbacks.onRemoteStream(streams[0], origin.publisherSocketId, origin.tipo);
    };
    subscriberPeerConnection.onicecandidate = ({ candidate }) => {
      const iceInfo: IceCandidateInfo = {
        type: 'subscriber',
        subscriberSocketId: this.socket.id,
        publisherSocketId: origin.publisherSocketId,
        publishId: origin.publishId,
      };
      /* console.log('emitingIceCandidate', iceInfo, candidate); */
      this.socket.emit('negotiation:iceCandidate', candidate, iceInfo);
    };

    this.configurarReconexaoPorProblemaDeIce(subscriberPeerConnection, () => {
      console.log(`Reiniciando subscribe ${subscribeConnectionKey} devido problema de Ice Candidate`);
      //Apenas invocamos o close pois o próprio processo de identificação de subscribe_end no server ja vai enviar uma nova offer
      if (subscriberPeerConnection) {
        subscriberPeerConnection.close();
        this.socket.emit(
          'SUBSCRIBE_ENDED',
          {
            subscriberSocketId: this.socket.id,
            publisherSocketId: origin.publisherSocketId,
            publishId: origin.publishId,
            connectionState: 'failed',
          },
          { tryToReconnect: true },
        );
      }
    });

    subscriberPeerConnection.onconnectionstatechange = event => {
      console.log(
        `subscriberPeerConnection to socket:${origin.publisherSocketId} - publishId: ${origin.publishId} ${subscriberPeerConnection.connectionState}`,
      );

      if (subscriberPeerConnection.connectionState === 'connected') {
        this.socket.emit('SUBSCRIBE_STARTED', {
          subscriberSocketId: this.socket.id,
          publisherSocketId: origin.publisherSocketId,
          publishId: origin.publishId,
          screenShare: origin.tipo == 'screen',
        });
      } else if (
        subscriberPeerConnection.connectionState === 'failed' ||
        subscriberPeerConnection.connectionState === 'closed'
      ) {
        console.log('encerrando subscriberPeerConnection', `${subscribeConnectionKey}`);
        this.limparPeerConnection(this.subscribersPeerConnetionPerPublishId.get(`${subscribeConnectionKey}`));
        this.subscribersPeerConnetionPerPublishId.delete(`${subscribeConnectionKey}`);
        this.socket.emit(
          'SUBSCRIBE_ENDED',
          {
            subscriberSocketId: this.socket.id,
            publisherSocketId: origin.publisherSocketId,
            publishId: origin.publishId,
            connectionState: subscriberPeerConnection.connectionState,
          },
          { tryToReconnect: true },
        );
      }
    };

    subscriberPeerConnection.oniceconnectionstatechange = ev => {
      console.log(
        `subscriberPeerConnection ${subscribeConnectionKey} connection state change`,
        this.publisherPeerConnection.iceConnectionState,
      );
    };

    await subscriberPeerConnection.setRemoteDescription(description);
    await subscriberPeerConnection.setLocalDescription(await subscriberPeerConnection.createAnswer());
    console.log('enviei Answer devido recibmendo de offer', subscriberPeerConnection.localDescription);
    this.sendDescription(subscriberPeerConnection.localDescription!, { publishId: origin.publishId });
  }

  private encerrarSubscribeSemForcarReconexao(subscribeConnectionKey: string) {
    const connection = this.subscribersPeerConnetionPerPublishId.get(`${subscribeConnectionKey}`);
    if (!connection) return;

    this.limparPeerConnection(connection);
    this.subscribersPeerConnetionPerPublishId.delete(`${subscribeConnectionKey}`);

    const publisherSocketId = subscribeConnectionKey.split('::::')[0];
    const publishId = subscribeConnectionKey.split('::::')[1];

    this.socket.emit(
      'SUBSCRIBE_ENDED',
      {
        subscriberSocketId: this.socket.id,
        publisherSocketId: publisherSocketId,
        publishId: publishId,
        connectionState: connection.connectionState,
      },
      { tryToReconnect: false },
    );
  }

  sendDescription(description: RTCSessionDescription, extraData?: SessionDescriptionDestination) {
    this.socket.emit('negotiation:sessionDescription', description, extraData);
  }

  disconnect() {
    if (this.publisherPeerConnection) {
      this.limparPeerConnection(this.publisherPeerConnection);
    }

    if (this.screenSharePeerConnection) {
      this.limparPeerConnection(this.screenSharePeerConnection);
    }

    this.subscribersPeerConnetionPerPublishId.forEach((v, k) => {
      this.subscribersPeerConnetionPerPublishId.delete(k);
      this.limparPeerConnection(v);
    });

    this.subscribersPeerConnetionPerPublishId = new Map();
  }

  async onIceCandidateReceived(iceCandidate: RTCIceCandidate, iceCandidateInfo: IceCandidateInfo) {
    console.log('iceCandidateReceived', iceCandidateInfo, iceCandidate);
    if (iceCandidateInfo.type == 'subscriber') {
      //Quando eu recebi um candidato de alguém dando subscribe, ele se conecta com meu publisher.
      await this.publisherPeerConnection.addIceCandidate(iceCandidate);
      console.log('adicionei iceCandidate na publisherPeerConnection', iceCandidateInfo, iceCandidate);
    } else {
      //se eu recebi um candidate de um publisher, então eu preciso encontrar a minha conexão de subscribe correspondente
      const subsPeerConnection = this.subscribersPeerConnetionPerPublishId.get(
        `${iceCandidateInfo.publisherSocketId}::::${iceCandidateInfo.publishId!}`,
      );
      if (subsPeerConnection) {
        try {
          await subsPeerConnection.addIceCandidate(iceCandidate);
          console.log('adicionei iceCandidate na subsPeerConnection', iceCandidateInfo, iceCandidate);
        } catch (err) {
          console.log('erro adicionando iceCandidate', iceCandidate, err);
        }
      } else {
        console.log('subscriberPeerConnection ainda não criado para o socketId:', iceCandidateInfo.publisherSocketId);
      }
    }
  }
}
