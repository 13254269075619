import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { CustomerComponentProps } from './CustomerComponentProps';

import { useAppDispatch, useAppSelector } from '../../../infra/redux/hooks';
import { selectJoinRoom } from '../JoinRoomSlice';
import { enableVBGImage } from '../../virtualBackground/VirtualBackgroundSlice';

const roleCliente = 'CLIENTE';
const roleConsultor = 'CONSULTOR';
const dominioCliente = 'sebraemg';

export function SebraeMGBotaoAvaliacao() {
  const joinRoomState = useAppSelector(selectJoinRoom);

  if (
    window.location.href.includes(dominioCliente) &&
    joinRoomState.urlParams.role &&
    joinRoomState.urlParams.role.toUpperCase() === roleCliente
  ) {
    return (
      <Button
        type="button"
        variant="primary"
        onClick={() => {
          window.location.href = `https://${dominioCliente}.connsult.com.br/Avaliacao/avaliar?id=${joinRoomState.token}`;
        }}
      >
        Sair e Avaliar Consultoria
      </Button>
    );
  }

  return null;
}

export function SebraeMGVirtualBackgrounds() {
  const dispatch = useAppDispatch();

  if (window.location.href.includes(dominioCliente)) {
    return (
      <>
        <Card
          key={`customerBG1`}
          className="effectItem"
          onClick={() =>
            dispatch(
              enableVBGImage({
                image: `/clientUploads/${window.location.hostname}/sebrae-vbg-1.webp`,
              }),
            )
          }
        >
          <img src={`/clientUploads/${window.location.hostname}/sebrae-vbg-1.webp`} width="150" height="84" />
        </Card>
      </>
    );
  }

  return null;
}
