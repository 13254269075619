import React, { useState, useRef } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { ReactMic } from 'react-mic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircle, faStop } from '@fortawesome/free-solid-svg-icons';

type FatalErrorModalProps = {
  show: boolean;
};

const FatalErrorModal = (props: FatalErrorModalProps) => {
  const { show } = props;

  return (
    <Modal show={show} keyboard={false} backdrop="static">
      <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
        <span className="ms-2">Erro de conexão</span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12">
            <p>Houve um problema ao acessar a sala.</p>
            <p>Por favor, entre novamente.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="p-2 text-center">
            <Button
              type="button"
              className="m-2"
              onClick={() => {
                document.location.reload();
              }}
            >
              Acessar novamente
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FatalErrorModal;
