import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infra/redux/reduxStore';

export interface VirtualBackgroundState {
  enabled: boolean;
  type: 'none' | 'blur' | 'image';
  image?: string;
}

const initialState: VirtualBackgroundState = {
  enabled: false,
  type: 'none',
};

export const virtualBackgroundSlice = createSlice({
  name: 'virtualBackground',
  initialState,
  reducers: {
    enableVBGBlur: (currentState, payload) => {
      return {
        enabled: true,
        type: 'blur',
      };
    },
    enableVBGImage: (currentState, payload) => {
      return {
        enabled: true,
        type: 'image',
        image: payload.payload.image,
      };
    },
    disableVBG: (currentState, payload) => {
      return {
        enabled: false,
        type: 'none',
      };
    },
  },
});

export const { enableVBGBlur, enableVBGImage, disableVBG } = virtualBackgroundSlice.actions;

export const selectVirtualBackground = (state: RootState) => state.virtualBackground;

export default virtualBackgroundSlice.reducer;
