import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import MyVideoRoom from './components/my-video-room/MyVideoRoom';
import JoinRoom from './pages/joinRoom/JoinRoom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import PageExitRoom from './pages/pageExitRoom';
import PageRoomExpired from './pages/pageRoomExpired';
import { Provider } from 'react-redux';
import { reduxStore } from './infra/redux/reduxStore';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://05654b2e888b4605a2422be79f9dd087@o293940.ingest.sentry.io/5609973',
    integrations: [new Integrations.BrowserTracing()],

    //Zerado pois não queremos tracking de performance
    tracesSampleRate: 0,
  });
}

/* console.stdlog = console.log.bind(console);
console.logs = [];
console.log = function() {
  if (console && console.logs !== undefined) {
    console.logs.push(Array.from(arguments));
    console.stdlog.apply(console, arguments);
  }
};
 */
const searchParams = new URLSearchParams(location.search);

if (searchParams.has('webview')) {
  console.log('SEARCHPARAMS WEBVIEW', searchParams.get('webview'));
  window.webview = searchParams.get('webview');
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Routes style={{ height: '100%' }}>
            <Route path="/" element={<JoinRoom />} />
            <Route path="/exit" element={<PageExitRoom />} />
            <Route path="/roomExpired" element={<PageRoomExpired />} />
            <Route path="/room/:token" element={<MyVideoRoom />} />
          </Routes>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
