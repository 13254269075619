import {
  faBan,
  faCalendar,
  faClipboard,
  faCog,
  faComment,
  faDesktop,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faPortrait,
  faShareAlt,
  faThLarge,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import centeredLayout from '../../assets/centeredLayout.svg';
import pipLayout from '../../assets/pipLayout.png';
import blur from '../../assets/blurBackground_white.png';

import React, { useCallback, useState } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';
import { selectJoinRoom } from './JoinRoomSlice';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SebraeGOButtons } from './customerComponents/SebraeGO';
import { Card, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import i18n from '../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import {
  disableVBG,
  enableVBGImage,
  enableVBGBlur,
  selectVirtualBackground,
} from '../virtualBackground/VirtualBackgroundSlice';
import VirtualBackgroundMenu from '../virtualBackground/VirtualBackgroundMenu';
import { selectToolbar, toogleShowDrawer } from './ToolbarSlice';
import { PsicologiaVivaToolBarButtons } from './customerComponents/PsicologiaViva';
import { selectDevices, setMicEnabled, setCamEnabled } from './DevicesSlice';
import PubSubWebRTCNegotiator from './PubSubWebRTCNegotiator';
import useGetStream from './useGetStream';

interface ToolbarProps {
  setShowExitRoomModal: (showExitRoomModal: boolean) => void;

  setMicEnabled: (micEnabled: boolean) => void;
  micEnabled: boolean;

  canShareScreen: boolean;
  shareScreen: boolean;

  layout: string;
  swapLayout: () => void;

  blurBackground: boolean;
  setblurBackground: (blurBackground: boolean) => void;

  setShowModalSelecionarMicCam: (showSelecionarMicCamModal: boolean) => void;

  chatOpen: boolean;
  setChatOpen: (chatOpen: boolean) => void;

  toggleShareScreen: () => void;
}

const Toolbar = function(props: ToolbarProps) {
  const dispatch = useAppDispatch();
  const joinRoomState = useAppSelector(selectJoinRoom);
  const toolbarState = useAppSelector(selectToolbar);
  const deviceState = useAppSelector(selectDevices);
  const { getNewStream } = useGetStream();

  const {
    toggleShareScreen,
    setShowExitRoomModal,
    canShareScreen,
    shareScreen,
    layout,
    swapLayout,
    setShowModalSelecionarMicCam,
    setChatOpen,
    chatOpen,
  } = props;

  const getRoomLink = async () => {
    await navigator.clipboard.writeText(`https://${document.location.host}/room/${joinRoomState.token}`);
    alert('Link da sala copiado para clipboard! Utilize Crtl + V para enviar o link.');
  };

  function toggleCam(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    getNewStream({ camEnabled: !deviceState.camEnabled });
  }

  return (
    <section className="toolbar">
      <div
        className="AnimationFPSdropworkarround"
        style={{
          position: 'absolute',
          left: 10,
          top: 10,
          width: '5px',
          height: '5px',
          opacity: 0.1,
          backgroundColor: 'red',
          animation: 'rotate 1.25s infinite',
        }}
      ></div>
      <i className="up_arrow"></i>

      <span className="btn-circle-container exitBtnContainer">
        <button
          className={`btn-circle btn-md disabled`}
          title="Sair da sala"
          onClick={() => {
            setShowExitRoomModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPhone as IconProp} className="icon" />
        </button>
        <label>sair</label>
      </span>

      {deviceState.temCamera && (
        <span className="btn-circle-container toggleCamBtnContainer">
          <button
            className={`btn-circle btn-md ${deviceState.camEnabled ? 'enabled' : 'disabled'}`}
            title={deviceState.camEnabled ? 'Desligar câmera' : 'Ligar câmera'}
            onClick={toggleCam}
          >
            <FontAwesomeIcon
              icon={deviceState.camEnabled ? (faVideo as IconProp) : (faVideoSlash as IconProp)}
              className="icon"
            />
          </button>
          <label>câmera</label>
        </span>
      )}

      <span className="btn-circle-container toggleMicBtnContainer">
        <button
          className={`btn-circle btn-md ${deviceState.micEnabled ? 'enabled' : 'disabled'}`}
          title={deviceState.micEnabled ? 'Desligar microfone' : 'Ligar microfone'}
          onClick={() => {
            dispatch(setMicEnabled(!deviceState.micEnabled));
          }}
        >
          <FontAwesomeIcon
            icon={deviceState.micEnabled ? (faMicrophone as IconProp) : (faMicrophoneSlash as IconProp)}
            className="icon"
          />
        </button>
        <label>microfone</label>
      </span>

      <span className="btn-circle-container configBtnContainer">
        <button
          className="btn-circle btn-md enabled"
          title="Configurações"
          onClick={() => setShowModalSelecionarMicCam(true)}
        >
          <FontAwesomeIcon icon={faCog as IconProp} className={'icon'} />
        </button>
        <label>configurações</label>
      </span>

      {!isMobile && (
        <>
          <span className="btn-circle-container shareScreenBtnContainer">
            <button
              disabled={!canShareScreen}
              className={`btn-circle btn-md ${shareScreen ? 'active' : 'enabled'}`}
              title="Compartilhar tela"
              onClick={() => toggleShareScreen()}
            >
              <FontAwesomeIcon icon={faDesktop as IconProp} className={'icon'} />
            </button>
            <label>compartilhar</label>
          </span>

          <span className="btn-circle-container layoutBtnContainer">
            <button className={`btn-circle btn-md enabled`} title="Layout" onClick={() => swapLayout()}>
              {layout === 'centeredLayout' && (
                <img className="icon" style={{ filter: 'none', transform: 'none' }} src={pipLayout}></img>
              )}
              {layout === 'pipLayout' && <FontAwesomeIcon icon={faThLarge as IconProp} className={'icon'} />}
              {layout === 'gridLayout' && <img className="icon" src={centeredLayout}></img>}
            </button>
            <label>layout</label>
          </span>

          <VirtualBackgroundMenu />
        </>
      )}

      <span className="btn-circle-container inviteBtnContainer">
        <button className="btn-circle btn-md enabled" title="Convidar" onClick={() => getRoomLink()}>
          <FontAwesomeIcon icon={faShareAlt as IconProp} className="icon" />
        </button>
        <label>Convidar</label>
      </span>

      <span className="btn-circle-container chatBtnContainer">
        <button
          className={`btn-circle btn-md ${chatOpen ? 'active' : 'enabled'}`}
          title="Chat"
          onClick={() => setChatOpen(!chatOpen)}
        >
          <FontAwesomeIcon icon={faComment as IconProp} className={'icon'} />
        </button>
        <label>chat</label>
      </span>

      {!isMobile && (
        <span className="btn-circle-container prontuarioBtnContainer">
          <button
            className={`btn-circle btn-md ${toolbarState.showDrawer ? 'active' : 'enabled'}`}
            title="Chat"
            onClick={() => dispatch(toogleShowDrawer())}
          >
            <FontAwesomeIcon icon={faClipboard as IconProp} className={'icon'} />
          </button>
          <label>prontuário</label>
        </span>
      )}

      <SebraeGOButtons />
      <PsicologiaVivaToolBarButtons />
    </section>
  );
};

export default withTranslation('translations')(Toolbar);
