import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infra/redux/reduxStore';

export interface ToolbarSlice {
  showDrawer: boolean;
  drawerWidth: number;
}

const initialState: ToolbarSlice = {
  showDrawer: false,
  drawerWidth: 0,
};

export const ToolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    toogleShowDrawer: currentState => ({
      ...currentState,
      showDrawer: !currentState.showDrawer,
      drawerWidth: currentState.showDrawer ? 0 : 40,
    }),
  },
});

export const { toogleShowDrawer } = ToolbarSlice.actions;

export const selectToolbar = (state: RootState) => state.toolbar;

export default ToolbarSlice.reducer;
