import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import VideoContainerSemRef from './fragmentVideoContainerSemRef';
import imgCameraSemPermissao from '../../assets/img-camera-sempermissao.png';
import FaltaPermissaoModal from './modals/FaltaPermissaoModal';
import toast, { Toaster } from 'react-hot-toast';

import { Alert, Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../infra/redux/hooks';

import { faVideo, faMicrophone } from '@fortawesome/free-solid-svg-icons';

import detectRTC from 'detectrtc';
import { getWebRTCUserMedia } from './MediaDevices';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import i18n from '../../i18n/i18n';
import {
  fetchedPermissions,
  selectDevices,
  setCamEnabled,
  setCameraEMicSelecionados,
  setCameraSelecionada,
  setHasMicCamPermission,
  setMicEnabled,
  setMicSelecionado,
  setTemCamera,
} from './DevicesSlice';
import { selectStreams, setMediaStream, useActiveStream } from './StreamsSlice';
import { reduxStore } from '../../infra/redux/reduxStore';
import { useForm } from 'react-hook-form';
import useGetStream from './useGetStream';
import ConexaoLentaModal from './modals/ConexaoLentaModal';

type SelecaoMicCameraModalProps = {
  iconClassName?: string;

  onPermissionGranted: () => void;
  onPermissionRevoked: () => void;
};

const MicCamPicker = (props: SelecaoMicCameraModalProps) => {
  const { onPermissionGranted, onPermissionRevoked } = props;

  const dispatch = useAppDispatch();
  const devicesState = useAppSelector(selectDevices);
  const streamsState = useAppSelector(selectStreams);

  const streamAtiva = useActiveStream();

  const { register, getValues } = useForm();

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
  const [showModalFaltaPermissao, setShowModalFaltaPermissao] = useState(false);

  const onErrorGettingStream = useCallback(() => {
    setShowModalFaltaPermissao(true);
  }, []);

  const onSuccessGettingStream = useCallback(() => {
    setShowModalFaltaPermissao(false);
  }, []);
  const { getNewStream } = useGetStream(onErrorGettingStream, onSuccessGettingStream);

  const configurarSelecaoDeDevicesAsync = useCallback(() => {
    detectRTC.load(() => {
      console.log('config de devices', detectRTC.videoInputDevices, detectRTC.audioInputDevices);

      const cameras = (detectRTC.videoInputDevices as unknown) as MediaDeviceInfo[];
      const mics = (detectRTC.audioInputDevices as unknown) as MediaDeviceInfo[];
      setCameras(cameras);
      setMics(mics);

      if (cameras.length === 0) {
        dispatch(setCamEnabled(false));
      }

      if (mics.length > 0) {
        setMicSelecionado(mics[0].deviceId);
      }
    });
  }, [dispatch]);

  useEffect(
    function configurarSelecaoDeDevices() {
      if (devicesState.hasMicCamPermission) {
        configurarSelecaoDeDevicesAsync();
      }
    },
    [configurarSelecaoDeDevicesAsync, devicesState.hasMicCamPermission],
  );

  useEffect(
    function setupAlreadySelectedDevices() {
      if (cameras.length > 0 && !devicesState.cameraSelecionada && mics.length > 0 && !devicesState.micSelecionado) {
        dispatch(
          setCameraEMicSelecionados({ cameraSelecionada: cameras[0].deviceId, micSelecionado: mics[0].deviceId }),
        );
      }
    },
    [cameras, mics, dispatch, devicesState.cameraSelecionada, devicesState.micSelecionado],
  );

  useEffect(
    function callOnPermissionGranted() {
      if (devicesState.hasMicCamPermission) {
        if (onPermissionGranted) {
          onPermissionGranted();
          configurarSelecaoDeDevicesAsync();
        }
      }
    },
    [configurarSelecaoDeDevicesAsync, devicesState.hasMicCamPermission, onPermissionGranted],
  );

  useEffect(
    function callOnPermissionRevoked() {
      if (devicesState.permissionsFetched && !devicesState.hasMicCamPermission) {
        setShowModalFaltaPermissao(true);

        //Comentado - Gerando infinitos registros
        //if (onPermissionRevoked) {
        //  console.log('OnPermissionRevoked');
        //  onPermissionRevoked();
        //}
        const timer = setInterval(() => {
          detectRTC.load(() => {
            getNewStream({ camEnabled: true, forceNewStream: true });
          });
        }, 3000);
        return () => {
          clearInterval(timer);
        };
      }
    },
    [devicesState.permissionsFetched, onPermissionRevoked, devicesState.hasMicCamPermission, getNewStream],
  );

  function updateStream() {
    const formValues = getValues();
    getNewStream(formValues);
  }

  useEffect(
    function pegarStreamSeNaoTiverStreamNaPrimeiraVez() {
      if (
        streamsState.mediaStream ||
        !devicesState.detectRTCloaded ||
        (devicesState.hasMicCamPermission && !devicesState.cameraSelecionada)
      ) {
        return;
      }

      getNewStream({ camEnabled: true, forceNewStream: true });
    },
    [
      devicesState.cameraSelecionada,
      devicesState.detectRTCloaded,
      devicesState.hasMicCamPermission,
      dispatch,
      getNewStream,
      streamsState.mediaStream,
    ],
  );

  const iconClassName = props.iconClassName || '';

  return (
    <div className="micCamPicker">
      <FaltaPermissaoModal show={showModalFaltaPermissao} />
      {!devicesState.hasMicCamPermission && (
        <div className="askPermission">
          <div className="localVideoContainer">
            <div
              style={{ height: '300px', width: '100%', backgroundColor: '#555555' }}
              className="localVideo text-center"
            >
              <img
                style={{ top: '75px', position: 'relative' }}
                src={imgCameraSemPermissao}
                alt="Câmera sem permissão"
              />
            </div>
          </div>

          <div className="controls col-12">
            <div className="videoSettings row">
              <div className="col-12">
                <Alert variant="danger">{i18n.t('solicitacaoAcessoCam')}</Alert>
              </div>
            </div>
            <div className="d-grid px-2">
              <Button size="lg" variant="success" type="button" onClick={() => getNewStream({ camEnabled: true })}>
                {`${i18n.t('Continuar')}...`}
              </Button>
            </div>
          </div>
        </div>
      )}
      {devicesState.hasMicCamPermission && (
        <div className="selectDevices">
          <div className="localVideoContainer">
            <VideoContainerSemRef
              microfoneLigado={devicesState.micEnabled}
              containerClassName=""
              videoClassName="col-md-12 localVideo"
              mostrarMicFeedback={true}
              reproduzirAudio={false}
              nomeParticipante=""
              videoMediaStream={streamAtiva}
              audioMediaStream={streamAtiva}
              cameraLigada={devicesState.camEnabled}
            />
          </div>
          <div className="controls col px-4 pt-4">
            <div className="videoSettings d-flex">
              <span className="d-inline-flex justify-content-center align-items-center">
                <Form.Switch
                  id="videoControl"
                  disabled={!devicesState.temCamera}
                  onChange={updateStream}
                  name="camEnabled"
                  label=" "
                  ref={register}
                  checked={devicesState.camEnabled}
                ></Form.Switch>

                <FontAwesomeIcon icon={faVideo as IconProp} className={`${iconClassName} me-2`} />
              </span>

              <Form.Control
                as="select"
                size="sm"
                onChange={updateStream}
                name="cameraSelecionada"
                ref={register}
                value={devicesState.cameraSelecionada}
              >
                {cameras &&
                  cameras.map((c, index) => {
                    return (
                      <option key={index} value={c.deviceId}>
                        {c.label}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>

            <div className="audioSettings d-flex mt-3">
              <span className="d-inline-flex justify-content-center align-items-center">
                <Form.Switch
                  id="audioControl"
                  onChange={updateStream}
                  label=" "
                  name="micEnabled"
                  ref={register}
                  checked={devicesState.micEnabled}
                  className=""
                ></Form.Switch>
                <FontAwesomeIcon icon={faMicrophone as IconProp} className={`${iconClassName} me-2`} />
              </span>

              <Form.Control
                as="select"
                size="sm"
                onChange={updateStream}
                name="micSelecionado"
                ref={register}
                value={devicesState.micSelecionado}
              >
                {mics &&
                  mics.map((c, index) => {
                    return (
                      <option key={index} value={c.deviceId}>
                        {c.label}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
//@ts-ignore
export default withTranslation('translations')(MicCamPicker);
