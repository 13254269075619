import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMicrophoneSlash, faWifi, faSlash } from '@fortawesome/free-solid-svg-icons';
import imgPersonPlaceHolder from '../../assets/Portrait_Placeholder.png';
import MicFeedback from './componentMicFeedback';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type VideoContainerProps = {
  audioMediaStream: MediaStream | undefined;
  videoMediaStream: MediaStream | undefined;
  microfoneLigado: boolean;
  mostrarMicFeedback: boolean;
  reproduzirAudio?: boolean;
  videoClassName?: string;
  containerClassName?: string;
  nomeParticipante: string;
  cameraLigada: boolean;
  gridArea?: string;
  streamId?: string;
  slowConnection?: boolean;
  shouldZoomOnBigContainer?: boolean;
  onSpeaking?: (streamId: string) => void;
};

const VideoContainerSemRef = (props: VideoContainerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoStyle, setVideoStyle] = useState<CSSProperties>({});

  useEffect(
    function atualizarVideoSrcObject() {
      if (!videoRef.current) {
        return;
      }

      //@ts-ignore: null
      videoRef.current.srcObject = props.videoMediaStream;
    },
    [props.videoMediaStream],
  );

  useEffect(
    function setupEsticarVideo() {
      //@ts-ignore
      const video = videoRef.current as HTMLVideoElement;

      const adjustObjectFit = () => {
        if (!props.shouldZoomOnBigContainer) return;

        const aspectRatio = video.videoWidth / video.videoHeight;
        if (aspectRatio > 1) {
          setVideoStyle({
            objectFit: 'cover',
            maxWidth: '900px',
          });
        } else {
          setVideoStyle({
            objectFit: 'contain',
          });
        }
      };

      video.onplay = adjustObjectFit;
      video.onresize = adjustObjectFit;

      return () => {
        video.onplay = () => {};
        video.onresize = () => {};
      };
    },
    [props.shouldZoomOnBigContainer, videoRef],
  );

  return (
    <div
      className={`videoContainer ${props.containerClassName ? props.containerClassName : ''}`}
      style={{ gridArea: props.gridArea }}
    >
      <video
        muted={!props.reproduzirAudio}
        className={props.videoClassName + (props.cameraLigada ? ' ' : ' hidden')}
        playsInline
        ref={videoRef}
        style={videoStyle}
        autoPlay
      />

      {!props.cameraLigada && (
        <div
          className={props.videoClassName + ' videoContainer_noCamera'}
          style={{ backgroundImage: 'url(' + imgPersonPlaceHolder + ')' }}
        >
          &nbsp;
        </div>
      )}

      {props.slowConnection && (
        <div className="slowConnectionOverlay">
          <span className="fa-layers fa-fw slowConnection">
            <FontAwesomeIcon icon={faWifi as IconProp} title="Conexão falhando" className="icon slowConnection" />
            <FontAwesomeIcon icon={faSlash as IconProp} title="Conexão falhando" className="icon slowConnection" />
            <p>Conexão fraca</p>
          </span>
        </div>
      )}

      <div className="userStatusContainer">
        {!props.microfoneLigado && (
          <FontAwesomeIcon
            icon={faMicrophoneSlash as IconProp}
            title="Microfone desabilitado"
            className="icon remoteMic"
          />
        )}

        {props.mostrarMicFeedback && props.microfoneLigado && (
          <MicFeedback
            microfoneLigado={props.microfoneLigado}
            stream={props.audioMediaStream}
            streamId={props.streamId}
            onSpeaking={props.onSpeaking}
            monitorarProblemasDeMicrofone={false}
          />
        )}

        <div className={`name ${props.cameraLigada ? '' : 'videoContainer-name_noCamera'}`}>
          {props.nomeParticipante}
        </div>
      </div>
    </div>
  );
};

export default VideoContainerSemRef;
