import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import VideoContainerSemRef from './fragmentVideoContainerSemRef';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faVideo, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import NonBlockingModal from '../modal/NonBlockingModal';

type ConectanteComStream = {
  videoStream: MediaStream;
  identificacao: string;
  socketId: string;
  micEnabled: boolean;
};

type StreamVideoDebbugerProps = {
  conectantesComMediaStream: ConectanteComStream[];
  show: boolean;
};

const StreamVideoDebbuger = (props: StreamVideoDebbugerProps) => {
  const [conectanteSelecionado, setConectanteSelecionado] = useState<any>(undefined);

  function trocarStreamSelecionada(socketId: string) {
    console.log(trocarStreamSelecionada);
    const conectante = props.conectantesComMediaStream.find(c => c.socketId === socketId);
    setConectanteSelecionado(conectante);
    console.log('Video stream selecionada para debug:', conectante?.videoStream);
  }

  return (
    <NonBlockingModal show={props.show}>
      <Form.Control
        as="select"
        size="sm"
        onChange={event => {
          trocarStreamSelecionada(event.target.value);
        }}
        value={conectanteSelecionado?.socketId}
        className="col-8"
      >
        <option />
        {props.conectantesComMediaStream &&
          props.conectantesComMediaStream.map((conectante, index) => {
            return (
              <option key={conectante.socketId} value={conectante.socketId}>
                {conectante.identificacao}
              </option>
            );
          })}
      </Form.Control>

      {conectanteSelecionado && (
        <VideoContainerSemRef
          microfoneLigado={conectanteSelecionado!.micEnabled}
          containerClassName=""
          videoClassName="col-md-12 localVideo"
          mostrarMicFeedback={true}
          reproduzirAudio={true}
          nomeParticipante=""
          videoMediaStream={conectanteSelecionado!.videoStream}
          audioMediaStream={conectanteSelecionado!.videoStream}
          cameraLigada={true}
        />
      )}
    </NonBlockingModal>
  );
};
//@ts-ignore
export default withTranslation('translations')(StreamVideoDebbuger);
