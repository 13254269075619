import React, { useState, useRef } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { ReactMic } from 'react-mic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircle, faStop } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type TesteMicrofoneModalProps = {
  show: boolean;
  onHide: () => void;
};

const TesteMicrofoneProps = (props: TesteMicrofoneModalProps) => {
  const { show, onHide } = props;
  const [gravando, setGravando] = useState(false);
  const refAudio = useRef<HTMLAudioElement>(null);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton style={{ display: 'flex', alignItems: 'center' }}>
        <span className="ms-2">Teste de microfone</span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12">
            <p>Parece que seu microfone não está funcionando.</p>
            <p>Aqui você pode gravar seu audio para fazer um teste rápido.</p>
            <p>Dica: Tente conectar e desconectar o seu microfone.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="p-2 text-center">
            <Button
              variant={gravando ? 'outline-danger' : 'outline-success'}
              onClick={() => setGravando(prevState => !prevState)}
              type="button"
              className="m-2"
            >
              <FontAwesomeIcon icon={gravando ? (faStop as IconProp) : (faCircle as IconProp)} className="me-2" />
              {gravando ? 'Parar gravação' : 'Iniciar teste'}
            </Button>
          </Col>
        </Row>
        <Row>
          <ReactMic
            record={gravando}
            visualSetting="frequencyBars"
            className="col-md-12"
            onStop={data => {
              if (refAudio.current) {
                refAudio.current.src = data.blobURL;
                refAudio.current.play();
              }
            }}
            strokeColor="#486CAB"
            backgroundColor="#FFFFFF"
          />
        </Row>
        <Row>
          <Col md="12" className="p-2 text-center">
            <audio controls ref={refAudio} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TesteMicrofoneProps;
